import { reactive, ref } from "vue"; // import { useI18n } from "vue-i18n";

import { useStore } from 'vuex';
import { useForm } from "vee-validate";
import { Counter } from '@/js/functions.js';
import { Login } from "@/js/services/baseService";
export default {
  omponents: {},
  props: {
    f7router: Object
  },
  setup: function setup(props) {
    // 多國語系
    // const { t, locale} = useI18n({
    //     messages: {
    //         "tw": {
    //         },
    //         en: {
    //         },
    //     }
    // });
    var f7router = props.f7router;
    var store = useStore();
    console.log('login page');
    var cData = reactive({
      btnDisabled: false
    }); // form驗證設定

    var _useForm = useForm({
      validateOnMount: false,
      validationSchema: {
        account: {
          required: true,
          verifyMobile: true
        },
        password: {
          required: true
        }
      },
      initialValues: {}
    }),
        errors = _useForm.errors,
        isSubmitting = _useForm.isSubmitting,
        submitCount = _useForm.submitCount,
        meta = _useForm.meta,
        values = _useForm.values,
        setFieldError = _useForm.setFieldError,
        setErrors = _useForm.setErrors,
        setFieldValue = _useForm.setFieldValue,
        setValues = _useForm.setValues,
        validate = _useForm.validate,
        validateField = _useForm.validateField,
        handleSubmit = _useForm.handleSubmit,
        submitForm = _useForm.submitForm,
        handleReset = _useForm.handleReset,
        resetForm = _useForm.resetForm; // 驗證帳號


    var onFormSubmit = handleSubmit(function (values, actions) {
      // return false;
      var account = values.account,
          password = values.password;
      var toSend = {
        account: account,
        password: password
      };
      cData.btnDisabled = true;
      Login(toSend).finally(function () {
        cData.btnDisabled = false;
      });
    });
    return {
      cData: cData,
      values: values,
      onFormSubmit: onFormSubmit,
      m_direct_page: m_direct_page
    };
  }
};