import { ref, computed } from 'vue';
import { useField } from "vee-validate";
export default {
  components: {},
  emits: ['update:modelValue', 'blur'],
  props: {
    modelValue: {
      default: ""
    },
    labelName: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    sty: {
      type: String,
      default: "underline"
    },
    type: {
      type: String,
      default: ""
    },
    inputmode: {
      type: String,
      default: 'text'
    },
    star: {
      // 必填星星
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    inputId: {
      type: String,
      default: ""
    },
    inputTag: {
      default: 'input'
    },
    inputClass: {
      type: String,
      default: ""
    },
    readonly: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ""
    },
    f7: {
      type: String,
      default: ""
    },
    // 高度
    h: {
      type: [String],
      default: ''
    },
    // textarea
    rows: {
      type: Number,
      default: 5
    },
    // textarea /
    material: {
      type: String,
      default: ""
    },
    ios: {
      type: String,
      default: ""
    },
    md: {
      type: String,
      default: ""
    },
    aurora: {
      type: String,
      default: ""
    },
    // validate
    errMsg: {
      type: String,
      default: ""
    }
  },
  setup: function setup(props, context) {
    var sty = props.sty,
        name = props.name,
        type = props.type;
    var refEl = ref(null);
    var refInput = ref(null);
    var thisType = ref(type);
    var computeStyArr = computed(function () {
      var newArr = [];
      sty.split(' ').forEach(function (sty, idx) {
        if (sty) {
          newArr.push(sty);
        }
      });
      return newArr;
    });
    var computeStyStr = computed(function () {
      var arr = computeStyArr.value.map(function (sty, idx) {
        return 'sty-' + sty;
      });
      return arr.join(' ');
    });
    var isCustomControls = computed(function () {
      return computeStyArr.value.indexOf('custom-controls') > -1;
    });
    var isSelect = computed(function () {
      return computeStyArr.value.indexOf('select') > -1;
    });
    var isCustom = computed(function () {
      return computeStyArr.value.indexOf('custom') > -1;
    });
    var isPassword = computed(function () {
      return computeStyArr.value.indexOf('password') > -1;
    });
    var isOutline = computed(function () {
      return computeStyArr.value.indexOf('outline') > -1;
    });
    var isAddress = computed(function () {
      return computeStyArr.value.indexOf('address') > -1;
    }); // password

    var togglePwd = function togglePwd() {
      if (thisType.value == 'password') {
        thisType.value = 'text';
      } else {
        thisType.value = 'password';
      }

      refInput.value.focus();
    }; // password /
    // validate


    var _useField = useField(name),
        thisErrMsg = _useField.errorMessage,
        thisName = _useField.name,
        value = _useField.value,
        meta = _useField.meta,
        errors = _useField.errors,
        resetField = _useField.resetField,
        validate = _useField.validate,
        handleChange = _useField.handleChange,
        handleBlur = _useField.handleBlur,
        setValidationState = _useField.setValidationState,
        checked = _useField.checked;

    return {
      console: console,
      refEl: refEl,
      refInput: refInput,
      thisType: thisType,
      computeStyArr: computeStyArr,
      computeStyStr: computeStyStr,
      isCustomControls: isCustomControls,
      isSelect: isSelect,
      isCustom: isCustom,
      isPassword: isPassword,
      isOutline: isOutline,
      isAddress: isAddress,
      thisErrMsg: thisErrMsg,
      // password
      togglePwd: togglePwd,
      meta: meta,
      errors: errors
    };
  }
};