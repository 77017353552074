import { Product } from "@/js/services/baseService";
import { computed, reactive } from "vue";
import creteStoreUtil from '@/store/store-util';
var defaultState = {
  // 焦點預購
  home_product_list: []
};

var _creteStoreUtil = creteStoreUtil({
  defaultState: defaultState
}),
    init = _creteStoreUtil.init,
    getData = _creteStoreUtil.getData,
    setData = _creteStoreUtil.setData,
    resetData = _creteStoreUtil.resetData,
    initState = _creteStoreUtil.initState;

export default {
  namespaced: true,
  state: function state() {
    return {};
  },
  getters: {
    getData: getData
  },
  actions: {
    init: init,
    // 焦點預購
    updateHomeProductList: function updateHomeProductList(_ref) {
      var commit = _ref.commit;
      return Product.home_product_list().then(function (res) {
        ajax200Res(res, function (_ref2) {
          var res = _ref2.res,
              data = _ref2.data,
              msg = _ref2.msg;

          // success
          if (data) {
            commit('setData', {
              home_product_list: data.product_list
            });
          }
        }, function (_ref3) {
          var res = _ref3.res,
              data = _ref3.data,
              msg = _ref3.msg,
              defaultAlert = _ref3.defaultAlert;
          // fail
          defaultAlert();
        });
      });
    }
  },
  mutations: {
    initState: initState,
    setData: setData,
    resetData: resetData
  }
};