import { computed, onMounted, reactive, ref } from "vue";
export default {
  props: {
    seconds: {
      type: Number,
      default: 0
    }
  },
  setup: function setup(props, context) {
    var cData = reactive({
      smallCountDown: false
    });
    var refRoot = ref(null);
    var seconds = computed(function () {
      return props.seconds;
    });
    var thisSeconds = seconds.value;
    var hh = ref('00');
    var mm = ref('00');
    var ss = ref('00');

    function secondsToFormat(seconds) {
      var sec_num = parseInt(seconds, 10); // don't forget the second param

      var hours = Math.floor(sec_num / 3600);
      var minutes = Math.floor((sec_num - hours * 3600) / 60);
      var seconds = sec_num - hours * 3600 - minutes * 60;

      if (hours < 10) {
        hours = "0" + hours;
      }

      if (minutes < 10) {
        minutes = "0" + minutes;
      }

      if (seconds < 10) {
        seconds = "0" + seconds;
      }

      return {
        hours: hours,
        minutes: minutes,
        seconds: seconds
      };
    }

    var interval = setInterval(function () {
      var info = secondsToFormat(thisSeconds);
      hh.value = info.hours;
      mm.value = info.minutes;
      ss.value = info.seconds;
      thisSeconds--;
      if (thisSeconds <= 0) clearInterval(interval);
    }, 1000);
    onMounted(function () {
      if (refRoot.value.clientWidth < 168) {
        cData.smallCountDown = true;
      }
    });
    return {
      cData: cData,
      refRoot: refRoot,
      hh: hh,
      mm: mm,
      ss: ss
    };
  }
};