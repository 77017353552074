export default {
  props: {
    price: {
      type: [Number, String],
      default: 0
    },
    points: {
      type: [Number, String],
      default: 0
    },
    pointsPrefix: {
      type: String
    },
    grayout: {
      type: Boolean,
      default: false
    },
    forceShowPoint: Boolean,
    forceShowPrice: Boolean
  },
  setup: function setup(props, context) {
    function trim(val) {
      return parseFloat(String(val).trim());
    }

    return {
      trim: trim
    };
  }
};