import { ref, computed } from "vue";
import NumBadge from "@/components/NumBadge";
import { useStore } from 'vuex';
export default {
  components: {
    NumBadge: NumBadge
  },
  props: {
    icon: {
      type: String,
      default: 'icon-cart1'
    },
    // 顯示總數
    all: {
      type: Boolean,
      default: false
    },
    // 顯示線上預購數
    onlinePreOrder: {
      type: Boolean,
      default: false
    },
    // 顯示行動超市數
    actionStore: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(props, context) {
    // store
    var store = useStore();
    var onlinePreOrder = computed(function () {
      if (store.state.cart.onlinePreorderNum) {
        return parseFloat(store.state.cart.onlinePreorderNum);
      } else {
        return 0;
      }
    });
    var actionStore = computed(function () {
      if (store.state.cart.actionStoreNum) {
        return parseFloat(store.state.cart.actionStoreNum);
      } else {
        return 0;
      }
    });
    var totalNum = computed(function () {
      return onlinePreOrder.value + actionStore.value;
    });
    var number = computed(function () {
      var result = 0;

      if (props.all) {
        result = totalNum.value;
      } else if (props.onlinePreOrder) {
        result = onlinePreOrder.value;
      } else if (props.actionStore) {
        result = actionStore.value;
      }

      return result;
    });
    return {
      store: store,
      number: number
    };
  }
};