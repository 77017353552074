import axios from "axios";
import mukiConst from '@/js/const.js';
import store from '@/store/store.js';
import { ajax200Res } from '@/js/utils';

let baseServiceAlert;

const apiBaseConfig = {
    baseURL: "/mobileApi/",
    withCredentials: false,
    headers: {
        Accept: "application/json",
        'Content-Type': 'application/form-data'
    },
    transformRequest: [
        function(data, headers){
            // 多國語系
            headers.common['X-Csrftoken'] = getCsrfTokenFromCookie();
            headers.common['MukiLang'] =  store.state.locale;
            headers['Authorization'] = `Bearer ${store.state.auth.token}`;

            return objToFormData(data)
        }
    ],
    transformResponse: [
        function(res){
            try {

                var parsed = JSON.parse(res)
                return parsed;

            }catch(err){

                alertCustom(
                    "訊息提示",
                    "資料發生錯誤，請聯繫維護人員處理"
                );
                
            }

        }
    ],
};

const { 
    baseURL, 
    headers,
    transformRequest, 
    transformResponse, 
    ...res 
} = apiBaseConfig;

const apiBase = axios.create(apiBaseConfig);

// 喜美網站API
const apiBaseWeb = axios.create({
    baseURL: process.env.NODE_ENV === 'development' ? '/civilWebApi/' : getWebApiBaseUrl(),
    headers,
    transformRequest: [
        function(data, headers){
            return objToFormData(data)
        }
    ],
    transformResponse,
})


// 陽昇API
const apiBaseYoungSun = axios.create({
    baseURL: "/youngSunApi/",
    headers,
    transformRequest, 
    transformResponse,
})


function getWebApiBaseUrl(){
    var formal = mukiConst.WEB_API_BASE_URL;
    var develop = 'https://civil-web-dev.muki001.com/webApi/';
    var master = 'https://civil-web.muki001.com/webApi/';
    var local = 'http://muki_civil_web.com/webApi/';

    if ( location.host == 'civil-mart-new-dev.muki001.com' ) {
        return develop;
    }

    if ( location.host == 'civil-mart-new.muki001.com' ) {
        return master;
    }

    return formal;
}

function setApiAuthconfig(){
    apiBaseWeb.defaults.headers['Authorization'] = `Bearer ${store.state.auth.token}`;
    apiBase.defaults.headers['Authorization'] = `Bearer ${store.state.auth.token}`;
    apiBaseYoungSun.defaults.headers['Authorization'] = `Bearer ${store.state.auth.token}`;
}

function getCsrfTokenFromCookie() {
    let regex = /.*csrf_cookie_name=([^;.]*).*$/;
    return document.cookie.match(regex) === null ? null : document.cookie.match(regex)[1];
}

const setApiMode = (apiBase, mode) => {
    // clone new apiBase
    var newApiBase = axios.create(apiBase.defaults);

    if (mode === 'dev') {
        newApiBase.defaults.baseURL = "/mockWebApi/";
    }

    return newApiBase;
}


function baseServiceOnErr(err){
    if ( baseServiceAlert && baseServiceAlert.opened ) {
        baseServiceAlert.close()
    } 

    baseServiceAlert = alertCustom(
        "訊息提示",
        "網路環境不穩定，請確認網路狀態再做嘗試"
    );
}

/**
 * 
 * @param {string} url 
 * @param {object} formInfo 
 * @param {object} config 
 * @property {string} config.mode 模式 
 * @property {object} config.apiBase 自訂的apiBase instance 
 * @returns {promise}
 */
function baseGet(url, formInfo, config) {
    var config = config || {};
    var useApiBase = config.apiBase ? config.apiBase : apiBase;
    
    var ps = setApiMode(useApiBase, config.mode).get(url, formInfo).catch(baseServiceOnErr);

    return ps;
}

/**
 * 
 * @param {string} url 
 * @param {object} formInfo 
 * @param {object} config 
 * @property {string} config.mode 模式 
 * @property {object} config.apiBase 自訂的apiBase instance 
 * @returns {promise}
 */
function basePost(url, formInfo, config) {
    var config = config || {};
    var useApiBase = config.apiBase ? config.apiBase : apiBase;

    var ps = setApiMode(useApiBase, config.mode).post(url, formInfo).catch(baseServiceOnErr);

    return ps;
}

/**
 * 
 * @param {string} url 
 * @param {object} formInfo 
 * @param {object} config 
 * @property {string} config.mode 模式 
 * @property {object} config.apiBase 自訂的apiBase instance 
 * @returns {promise}
 */
function basePut(url, formInfo, config ) {
    var config = config || {};
    var useApiBase = config.apiBase ? config.apiBase : apiBase;

    var ps = setApiMode(useApiBase, config.mode).put(url, formInfo).catch(baseServiceOnErr);
    
    return ps;
}

/**
 * 
 * @param {string} url 
 * @param {object} formInfo 
 * @param {object} config 
 * @property {string} config.mode 模式 
 * @property {object} config.apiBase 自訂的apiBase instance 
 * @returns {promise}
 */
function basePatch(url, formInfo, config) {
    var config = config || {};
    var useApiBase = config.apiBase ? config.apiBase : apiBase;

    var ps = setApiMode(useApiBase, config.mode).patch(url, formInfo).catch(baseServiceOnErr);

    return ps;
}

/**
 * 
 * @param {string} url 
 * @param {object} formInfo 
 * @param {object} config 
 * @property {string} config.mode 模式 
 * @property {object} config.apiBase 自訂的apiBase instance 
 * @returns {promise}
 */
function baseDel(url, formInfo, config) {
    var config = config || {};
    var useApiBase = config.apiBase ? config.apiBase : apiBase;

    var ps = setApiMode(useApiBase, config.mode).delete(url, formInfo).catch(baseServiceOnErr);

    return ps;
}

// 首頁
const Index = {
    /**
     * 首頁
     * @param {Object} formInfo
     * 
     * @return {Object} response
     */
    index(formInfo, mode) {
        return basePost('/api_index/index', formInfo, {apiBase: apiBaseWeb}).then(res => {
            return res;
        })
    },
}

// 信件
const Mail = {
    detailed(formInfo, mode){
        return basePost('/api_mail/mail_detailed', formInfo, {mode}).then(res => {
            const { data, status } = res;
            const resData = data['res_data'];
            if (status === 200 && data["res_code"] === 1) {
            } else {
                alertCustom("訊息提示", data["res_content"], () => {
                });
            };
            return res;
        })
    },
    read(formInfo, mode) {
        return basePost('/api_mail/mail_read', formInfo, {mode}).then(res => {
            const { data, status } = res;
            const resData = data['res_data'];
            if (status === 200 && data["res_code"] === 1) {
            } else {
                alertCustom("訊息提示", data["res_content"],() => {
                });
            };
            return res;
        })
    },
    delete(formInfo, mode) {
        return basePost('/api_mail/mail_delete', formInfo, {mode}).then(res => {
            const { data, status } = res;
            const resData = data['res_data'];
            if (status === 200 && data["res_code"] === 1) {
            } else {
                alertCustom("訊息提示", data["res_content"], () => {
                });
            };
            return res;
        })
    },
}

// DM
const DM = {
    list(formInfo, mode) {
        return basePost('/api_dm/dm_list', formInfo, {mode, apiBase: apiBaseWeb}).then(res => {
            return res;
        })
    },
}


// 產品
const Product = {
    /**
     * 產品詳細資料
     * @param {Object} formInfo
     * @property {Number} formInfo.product_id - 第幾頁 (1, 預設1)
     * 
     * @return {Object} response
     * @property {Number} formInfo.id - (1)
     * @property {Number} formInfo.is_collect -  ()
     * @property {Number} formInfo.spec_id -  ()
     * @property {String} formInfo.name -  ()
     * @property {Number} formInfo.stock -  ()
     * @property {Number} formInfo.ori_price -  ()
     * @property {Number} formInfo.price -  ()
     * @property {Number} formInfo.points -  ()
     * @property {Array} formInfo.pics -  ()
     * @property {Array} formInfo.pics[0] -  ()
     * @property {Array} formInfo.pics[0][0] - 照片連結 ("/assets/product_picture_20220329174108939823.jpg")
     * 
     * @property {Object} formInfo.sub_products - ()
     * @property {Object} formInfo.sub_products[key] - ()
     * @property {String} formInfo.sub_products[key].name - ("黑1")
     * @property {Object} formInfo.sub_products[key].sub - ()
     * @property {Number} formInfo.sub_products[key].sub[key].id - ("475")
     * @property {String} formInfo.sub_products[key].sub[key].name - ("白1")
     * @property {Number} formInfo.sub_products[key].sub[key].stock - ("50")
     * @property {Array} formInfo.sub_products[key].sub[key].packages - ()
     * @property {Number} formInfo.sub_products[key].sub[key].packages[0].id - ("5")
     * @property {Number} formInfo.sub_products[key].sub[key].packages[0].price - ("5")
     * @property {Number} formInfo.sub_products[key].sub[key].packages[0].points - ("5")
     * 
     * @property {Object} formInfo.pickup_date - ("2022-03-29~9999-12-31")
     * @property {Array} formInfo.description_list - ()
     * @property {Number} formInfo.description_list[0].id - ("38")
     * @property {Number} formInfo.description_list[0].product_id - ("38")
     * @property {String} formInfo.description_list[0].topic - ("商品特色")
     * @property {String} formInfo.description_list[0].content - ("")
     */
    detailed(formInfo, mode) {
        return basePost('/api_product/product_detailed', formInfo, {mode}).then(res => {
            return res;
        })
    },
    // 加入購物車
    addToCart(formInfo, mode) {
        var info = {
            "product_id": null,
            "product_spec_id": null,
            "amount": 1,
            "action_type": 'addition',
        }
        var formInfo = Object.assign({}, info, formInfo);

        return basePost('/api_cart/cart_items_process', formInfo, {mode}).then(res => {
            return res;
        })
    },
    // 修改購物車數量
    AlterCart(formInfo, mode) {
        var info = {
            "product_id": null,
            "product_spec_id": null,
            "amount": null,
            "action_type": 'change_rewrite',
        }
        var formInfo = Object.assign({}, info, formInfo);

        return basePost('/shopping_cart/cart_items_process', formInfo, {mode}).then(res => {
            return res;
        })
    },
    // 移出購物車
    removeFromCart(formInfo, mode) {
        var info = {
            "product_id": null,
            "product_spec_id": null,
            // 產品SPEC_PEICE_ID(只有預購需填寫)
            "product_spec_price_id": null,
            // action:行動超市,website:預購
            "cart_type": null,
        }
        var formInfo = Object.assign({}, info, formInfo);

        return basePost('/shopping_caapi_cartrt/cart_items_remove', formInfo, {mode}).then(res => {
            return res;
        })
    },

    /**
     * 取得線上預購分類
     * @param {Object} formInfo
     * 
     * @return {Object} response
     * @property {Array} formInfo.product_category -  ()
     * @property {string} formInfo.product_category[0].id -  (35) 
     * @property {string} formInfo.product_category[0].name -  (人氣熱銷) 
     */
    product_category(formInfo, mode) {
        return baseGet('/api_product/product_category', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 取得線上預購產品列表
     * @param {Object} formInfo
     * @property {Number} formInfo.page - 第幾頁 (1, 預設1)
     * @property {Number} formInfo.per_page - 一頁幾筆 (10, 預設10)
     * @property {Number} formInfo.category - 分類ID ('', 為空值時顯示所有商品)
     * 
     * @return {Object} response
     * @property {Number} formInfo.total_page - (1)
     * @property {Array} formInfo.product_list -  ()
     * @property {string} formInfo.product_list[0].id -  (35)
     * @property {string} formInfo.product_list[0].spec_id -  (475)
     * @property {string} formInfo.product_list[0].is_collect -  (0)
     * @property {string} formInfo.product_list[0].countdown -  (9999-12-31 23:59:59)
     * @property {string} formInfo.product_list[0].name -  (全規則商品(勿刪)/黑1 白1)
     * @property {string} formInfo.product_list[0].pic_src -  (/assets/uploads/800x600/product_picture_20220329174108939823.jpg)
     * @property {string} formInfo.product_list[0].stock -  (50)
     * @property {string} formInfo.product_list[0].ori_price -  (200)
     * @property {Number} formInfo.product_list[0].price -  (150)
     * @property {Number} formInfo.product_list[0].points -  (20)
     */
    product_list(formInfo, mode) {
        return basePost('/api_product/product_list', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 取得線上預購產品詳細資料
     * @param {Object} formInfo
     * @property {Number} formInfo.product_id - 線上預購產品ID (9)
     * 
     * @return {Object} response
     * @property {Number} formInfo.id - (9)
     * @property {Number} formInfo.is_collect - (0)
     * @property {Number} formInfo.spec_id - (475)
     * @property {String} formInfo.name - (全規則商品(勿刪)/黑1 白1)
     * @property {Number} formInfo.stock - (50)
     * @property {Number} formInfo.ori_price - (200)
     * @property {Number} formInfo.price - (150)
     * @property {Number} formInfo.points - (20)
     * @property {Array} formInfo.pics - ()
     * @property {Array} formInfo.pics[0] - ()
     * @property {String} formInfo.pics[0][0] - (/assets/uploads/800x600/product_picture_20220329174108939823.jpg)
     * 
     * @property {Object} formInfo.sub_products -  ()
     * @property {string} formInfo.sub_products[key].name -  (黑1)
     * @property {Object} formInfo.sub_products[key].sub -  ()
     * @property {Number} formInfo.sub_products[key].sub[key].id -  (475)
     * @property {String} formInfo.sub_products[key].sub[key].name -  (白1)
     * @property {Number} formInfo.sub_products[key].sub[key].stock -  (50)
     * @property {Array} formInfo.sub_products[key].sub[key].packages -  ()
     * @property {Number} formInfo.sub_products[key].sub[key].packages[0].id -  (5)
     * @property {Number} formInfo.sub_products[key].sub[key].packages[0].price -  (150)
     * @property {Number} formInfo.sub_products[key].sub[key].packages[0].points -  (150)
     * 
     * @property {Sring} formInfo.pickup_date -  (2022-03-29~9999-12-31)
     * @property {Array} formInfo.description_list -  ()
     * @property {Number} formInfo.description_list[0].id -  (38)
     * @property {Number} formInfo.description_list[0].product_id -  (38)
     * @property {String} formInfo.description_list[0].topic -  (商品特色)
     * @property {String} formInfo.description_list[0].content -  ()
     */
    product_detailed(formInfo, mode) {
        return baseGet('/api_product/product_detailed', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 取得線上預購推薦產品
     * @param {Object} formInfo
     * @property {Number} formInfo.product_id - 線上預購產品ID (9)
     * 
     * @return {Object} response
     * @property {Array} formInfo.product_list -  ()
     * @property {string} formInfo.product_list[0].id -  (35)
     * @property {string} formInfo.product_list[0].spec_id -  (475)
     * @property {string} formInfo.product_list[0].is_collect -  (0)
     * @property {string} formInfo.product_list[0].countdown -  (9999-12-31 23:59:59)
     * @property {string} formInfo.product_list[0].name -  (全規則商品(勿刪)/黑1 白1)
     * @property {string} formInfo.product_list[0].pic_src -  (/assets/uploads/800x600/product_picture_20220329174108939823.jpg)
     * @property {string} formInfo.product_list[0].stock -  (50)
     * @property {string} formInfo.product_list[0].ori_price -  (200)
     * @property {Number} formInfo.product_list[0].price -  (150)
     * @property {Number} formInfo.product_list[0].points -  (20)
     */
    recommend(formInfo, mode) {
        console.log('formInfo',formInfo);
        return basePost('/api_product/product_recommend', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 焦點預購
     * @param {Object} formInfo
     * 
     * @return {Object} response
     * @property {Number} formInfo.total_page - (1)
     * @property {Array} formInfo.product_list -  ()
     * @property {string} formInfo.product_list[0].id -  (35)
     * @property {string} formInfo.product_list[0].spec_id -  (475)
     * @property {string} formInfo.product_list[0].is_collect -  (0)
     * @property {string} formInfo.product_list[0].countdown -  (9999-12-31 23:59:59)
     * @property {string} formInfo.product_list[0].name -  (全規則商品(勿刪)/黑1 白1)
     * @property {string} formInfo.product_list[0].pic_src -  (/assets/uploads/800x600/product_picture_20220329174108939823.jpg)
     * @property {string} formInfo.product_list[0].stock -  (50)
     * @property {string} formInfo.product_list[0].ori_price -  (200)
     * @property {Number} formInfo.product_list[0].price -  (150)
     * @property {Number} formInfo.product_list[0].points -  (20)
     */
    home_product_list(formInfo, mode) {
        return baseGet('/api_product/home_product_list', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 線上預購-加入蒐藏
     * @param {Object} formInfo
     * @property {Number} formInfo.product_id - (9)
     * 
     * @return {Object} response
     */
    add_collect(formInfo, mode) {
        return basePost('/api_product/add_collect', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 線上預購-取消蒐藏
     * @param {Object} formInfo
     * @property {Number} formInfo.product_id - 產品ID (9)
     * 
     * @return {Object} response
     */
    remove_collect(formInfo, mode) {
        return basePost('/api_product/remove_collect', formInfo, {mode}).then(res => {
            return res;
        })
    },
}


// 購物車
const Cart = {
    /**
     * 取得行動超市推薦產品
     * @param {Object} formInfo
     * @property {String} formInfo.cart_type - (action:行動超市,website:預購)
     * 
     * @return {Object} response
     * @property {Array} response.product_list - ()
     * @property {Number} response.product_list[0].id - (9)
     * @property {Number} response.product_list[0].spec_id - (9)
     * @property {Number} response.product_list[0].is_collect - (0)
     * @property {String} response.product_list[0].countdown - (9999-12-31 00:00:00)
     * @property {String} response.product_list[0].name - (預設商品)
     * @property {String} response.product_list[0].pic_src - (/assets/uploads/800x600/product_picture_20220315104432792353.png)
     * @property {Number} response.product_list[0].stock - (9)
     * @property {Number} response.product_list[0].ori_price - (0.00)
     * @property {Number} response.product_list[0].price - (0.00)
     * @property {Number} response.product_list[0].points - (0)
     */
    recommend(formInfo, mode) {
        console.log('formInfo',formInfo);
        return basePost('/api_cart/product_recommend', formInfo, {mode}).then(res => {
            return res;
        })
    },

    // 購物車產品數量
    current_cart_num(formInfo, mode) {
        return basePost('/api_cart/current_cart_num', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 加入購物車/修改購物車產品數量
     * @param {Object} formInfo
     * @property {Number} formInfo.product_id - 產品ID ()
     * @property {Number} formInfo.product_spec_id - 產品SPECID ()
     * @property {String} formInfo.cart_type - (action:行動超市,website:預購)
     * @property {Number} formInfo.amount - 數量 (1)
     * @property {Number} formInfo.type - (rewrite: 複寫、addition: 增加)
     * @property {Number} formInfo.product_spec_price_id - 產品SPEC_PRICE_ID(只有預購需填寫) (9)
     * 
     * @return {Object} response
     */
    cart_items_process(formInfo, mode) {
        return basePost('/api_cart/cart_items_process', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 移除購物車商品
     * @param {Object} formInfo
     * @property {Number} formInfo.product_id - 產品ID ()
     * @property {Number} formInfo.product_spec_id - 產品SPECID ()
     * @property {Number} formInfo.product_spec_price_id - 產品SPEC_PRICE_ID(只有預購需填寫) (9)
     * @property {String} formInfo.cart_type - (action:行動超市,website:預購)
     * 
     * @return {Object} response
     */
    cart_items_remove(formInfo, mode) {
        return basePost('/api_cart/cart_items_remove', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 取得購物車資訊
     * @param {Object} formInfo
     * @property {String} formInfo.cart_type - (action:行動超市,website:預購)
     * 
     * @return {Object} response
     * @property {String} response.id - (9)
     * @property {String} response.category_id - (0)
     * @property {String} response.spec_id - (0)
     * @property {String} response.spec_price_id - (0)
     * @property {String} response.pic_src - 商品圖片 (/product_picture_20220329174108939823.jpg)
     * @property {String} response.stock - 商品庫存 ()
     * @property {String} response.amount - 購買數量 ()
     * @property {Number} response.price - 商品價格 ()
     * @property {Number} response.points - 商品點數 ()
     * @property {Number} response.name - 商品名稱 ()
     * @property {Number} response.specification_name - 規格名稱 ()
    */
    get_current_cart(formInfo, mode) {
        return basePost('/api_cart/get_current_cart', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 加入購物車/修改購物車產品數量
     * @param {Object} formInfo
     * @property {String} formInfo.cart_type - (action:行動超市,website:預購)
     * @property {Number} formInfo.coupon_id - 優惠劵ID (1)
     * @property {Number} formInfo.check_checkout_data - 勾取結帳的商品  action ( action => {產品ID:1}  website => {產品SPEC_ID:1} ) ({"476":1})
     * 
     * @return {Object} response
     */
    cart_step1(formInfo, mode) {
        return basePost('/api_cart/cart_step1', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 購物-第二步-線上預購
     * @param {Object} formInfo
     * @property {String} formInfo.picker_mobile - 取貨人電話 (0912345678)
     * @property {String} formInfo.picker_name - 取貨人姓名 (mukitest)
     * @property {Number} formInfo.store_id - 商店代碼 (1001)
     * 
     * @return {Object} response
     */
    website_cart_step2(formInfo, mode) {
        return basePost('/api_cart/website_cart_step2', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 購物-第二步-行動超市
     * @param {Object} formInfo
     * @property {String} formInfo.picker_mobile - 取貨人電話 (0912345678)
     * @property {String} formInfo.picker_name - 取貨人姓名 (mukitest)
     * @property {String} formInfo.action_store_id - 行動超市ID (1)
     * @property {String} formInfo.pick_up_area - 取貨地點名稱 (長億大樓)
     * @property {String} formInfo.pick_up_address - 取貨地點 (台中市西屯區台灣大道2段910號)
     * @property {String} formInfo.pick_up_date - 取貨日期 (2022-05-11)
     * @property {String} formInfo.pick_up_time - 取貨時段 (11:00~12:00)
     * @property {Number} formInfo.product_short - 商品短缺時 (0:取消整筆訂單 1:只領取有貨的項目)
     * 
     * @return {Object} response
     */
    action_cart_step2(formInfo, mode) {
        return basePost('/api_cart/action_cart_step2', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 購物-第三步-行動超市
     * @param {Object} formInfo
     * @property {String} formInfo.cart_type - (action:行動超市,website:預購)
     * 
     * 行動超市
     * @return {Object} response
     * @property {String} response.id - ()
     * @property {String} response.orders_number - (PL20220526183037)
     * @property {String} response.action_store_name - (長億大樓)
     * @property {String} response.action_store_address - (台中市西屯區台灣大道2段910號)
     * @property {String} response.pick_up_date - (2022/03/29)
     * @property {String} response.pick_up_time - (11:00~12:00)
     * 
     * 線上預購
     * @return {Object} response
     * @property {String} response.orders_id - (41)
     * @property {String} response.orders_number - (PL20220526183037)
     * @property {String} response.store_name - ()
     * @property {String} response.get_start_time - (2022/03/29)
     * @property {String} response.get_end_time - (2022/03/29)
     */
    cart_step3(formInfo, mode) {
        return basePost('/api_cart/cart_step3', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 購物車-選擇行動超市(改變當前購物車選擇的行動超市)
     * 
     * 備註 : 源容:呼叫此api時，需確認購物車內，行動超市有商品數量>0，否則選擇是不會被記錄的
     * 因此最好是在item_process後確認數量是>0(行動超市購物車)，才執行本api
     * 
     * @param {Object} formInfo
     * @property {String} formInfo.action_store_id - 行動超市ID (1)
     * 
     * @return {Object} response
     */
    select_action_store(formInfo, mode) {
        return basePost('/api_cart/select_action_store', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 取得購物車-行動超市ID
     * @return {Object} response
     * @property {String} response.action_store_id - ()
     */
    get_cart_action_store_id(formInfo, mode) {
        return basePost('/api_cart/get_cart_action_store_id', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 取得購物車運費級距
     * @param {Object} formInfo
     * @property {String} formInfo.cart_type - (action:行動超市,website:預購)
     * 
     * @return {Object} response
     * @property {String} response.start_achieve_price - (0 運費價格區間(起))
     * @property {String} response.end_achieve_price - (299 運費價格區間(迄))
     * @property {String} response.freight - (70 運費)
    */
    get_freight_interval(formInfo, mode) {
        return basePost('/api_cart/get_freight_interval', formInfo, {mode}).then(res => {
            return res;
        })
    },


    
}

// 生活提案
const Proposal = {
    // 分類
    category(formInfo, mode) {
        return basePost('/api_proposal/proposal_category', formInfo, {apiBase: apiBaseWeb}).then(res => {
            return res;
        })
    },
    // 列表
    /**
     * @param {Object} formInfo
     * @property {number} formInfo.page - 頁數
     * @property {number} formInfo.category - 分類
     */
    list(formInfo, mode) {
        return basePost('/api_proposal/proposal_list', formInfo, {apiBase: apiBaseWeb}).then(res => {
            return res;
        })
    },
    // 詳細
    detailed(formInfo, mode) {
        return basePost('/api_proposal/proposal_detailed', formInfo, {apiBase: apiBaseWeb}).then(res => {
            const { data, status } = res;
            const resData = data['res_data'];
            if (status === 200 && data["res_code"] === 1) {
            } else {
                alertCustom("訊息提示", data["res_content"], () => {
                });
            };
            return res;
        })
    },
}

// 優惠券
const Coupon = {
    // 列表
    list(formInfo, mode) {
        return basePost('/api_member/coupon_list', formInfo, {mode}).then(res => {
            const { data, status } = res;
            const resData = data['res_data'];
            if (status === 200 && data["res_code"] === 1) {
            } else {
                alertCustom("訊息提示", data["res_content"], () => {
                });
            };
            return res;
        })
    },
}

// 門市
const Store = {
    /**
     * 取得門市類別
     * @param {Object} formInfo
     * 
     * @return {Array} response
     * @property {Object} response[0] -  ()
     * @property {String} response[0].id - id (2)
     * @property {String} response[0].class_name - 門市名稱 (喜美門市)
     * @property {String} response[0].is_show_backend - is_show_backend (1)
     * @property {String} response[0].sort_id - sort_id (2)
     * @property {String} response[0].status - status (2)
     * @property {String} response[0].modifier - modifier (mukidev)
     * @property {String} response[0].create_time - create_time (2019-02-26 08:37:45)
     * @property {String} response[0].modify_time - modify_time (2019-02-26 08:37:45)
     */
    store_category(formInfo, mode) {
        return basePost('/api_store/store_category', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 取得門市列表
     * @param {Object} formInfo
     * @property {Number} formInfo.category - 分類ID(不填寫視為所有分類) (2)
     * 
     * @return {Array} response
     * @property {Object} response[0] -  ()
     * @property {String} response[0].id - id (2)
     * @property {String} response[0].class_id - class_id (2)
     * @property {String} response[0].store_name - store_name (不顯示門市1)
     * @property {String} response[0].erp_code - erp_code (123)
     * @property {String} response[0].manager_name - manager_name (2)
     * @property {String} response[0].phone - phone ()
     * @property {String} response[0].fax - fax ()
     * @property {String} response[0].email - email ()
     * @property {String} response[0].addr - addr ()
     * @property {String} response[0].business_time - business_time ()
     * @property {String} response[0].pic1 - pic1 ()
     * @property {String} response[0].google_maps_code - google_maps_code ()
     * @property {String} response[0].longitude - longitude (0.000000)
     * @property {String} response[0].latitude - latitude (0.000000)
     * @property {String} response[0].is_show_front - is_show_front (2)
     * @property {String} response[0].sort_id - sort_id (2)
     * @property {String} response[0].status - status (2)
     * @property {String} response[0].modifier - modifier (admin)
     * @property {String} response[0].create_time - create_time (2020-12-11 09:42:14)
     * @property {String} response[0].modify_time - modify_time (2020-12-11 09:42:14)
     */
    store_list(formInfo, mode) {
        return basePost('/api_store/store_list', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 取得門市詳細資料
     * @param {Object} formInfo
     * @property {Number} formInfo.store_id - 分類ID(不填寫示為所有分類) ()
     * 
     * @return {Array} response
     * @property {Number} response.id -  ()
     * @property {Number} response.class_id -  ()
     * @property {String} response.store_name -  ()
     * @property {String} response.erp_code -  ()
     * @property {String} response.manager_name -  ()
     * @property {String} response.phone -  ()
     * @property {String} response.fax -  ()
     * @property {String} response.email -  ()
     * @property {String} response.addr -  ()
     * @property {String} response.business_time -  ()
     * @property {String} response.pic1 -  ()
     * @property {String} response.google_maps_code -  ()
     * @property {String} response.longitude -  ()
     * @property {String} response.latitude -  ()
     * @property {String} response.is_show_front -  ()
     * @property {String} response.sort_id -  ()
     * @property {String} response.status -  ()
     * @property {String} response.modifier -  ()
     * @property {String} response.create_time -  ()
     * @property {String} response.modify_time -  ()
     */
    store_detail(formInfo, mode) {
        return basePost('/api_store/store_detail', formInfo, {mode}).then(res => {
            return res;
        })
    },
}

// 客服中心
const Contact = {
    // // 分類
    // category(formInfo, mode) {
    //     return basePost('/api_customer_service/question_category', formInfo, mode).then(res => {
    //         return res;
    //     })
    // },
    // // 列表
    // contactUs(formInfo, mode) {
    //     return basePost('/api_customer_service/contact_us', formInfo, mode).then(res => {
    //         return res;
    //     })
    // },
    /**
     * 取得問題類別
     * @param {Object} formInfo
     * 
     * @return {Array} response
     * @property {String} response[0].id - id (2)
     * @property {String} response[0].category_name -  ()
     * @property {String} response[0].sort_id -  ()
     * @property {String} response[0].status -  ()
     * @property {String} response[0].modifier -  (mukidev)
     * @property {String} response[0].create_time - (2020-10-27 11:36:53)
     * @property {String} response[0].modify_time -  (2019-02-26 08:37:45)
     */
    question_category(formInfo, mode) {
        return baseGet('/api_contact/question_category', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 取得問題類別
     * @param {Object} formInfo
     * @property {String} formInfo.name - 名稱 ()
     * @property {String} formInfo.mobile - 電話 (0912345678)
     * @property {String} formInfo.question_category - 問題類別ID (1)
     * @property {String} formInfo.description - 問題描述 (2)
     * @property {String} formInfo.captcha - 驗證碼 (123456)
     * 
     * @return {Array} response
     */
    contact_us(formInfo, config) {
        return basePost('/api_contact/contact_us', formInfo, config).then(res => {
            return res;
        })
    },
}


// 最新消息
const News = {
    // 列表
    list(formInfo, mode) {
        return basePost('/api_news/news_list', formInfo, {apiBase: apiBaseWeb}).then(res => {
            return res;
        })
    },
    // 列表
    detailed(formInfo, mode) {
        return basePost('/api_news/news_detailed', formInfo, {apiBase: apiBaseWeb}).then(res => {
            return res;
        })
    },
}


// 訂單
const Order = {
    // 列表
    /**
     * 待領取訂單數量
     * @param {Object} formInfo
     * 
     * @return {Object} response
     */
    get_unreceive_orders_num(formInfo, mode) {
        return basePost('/api_orders/get_unreceive_orders_num', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 訂單查詢
     * @param {Object} formInfo
     * @property {string} formInfo.orders_type - ( "website:線上預購,action:行動超市" )
     * @property {Number} formInfo.orders_status - 訂單狀態 ( 0:待領取、1:已領取、9:取消訂單 )
     * @property {Number} formInfo.send_youngsun - 訂單狀態 ( 0:未傳輸、1:已傳輸 (無填寫時視為1) )
     * 
     * @return {Object} response
     * @property {String} response[0].id - id (2)
     * @property {String} response[0].orders_time - (2022-03-15 14:2)
     * @property {String} response[0].orders_number - (C202203150001)
     * @property {String} response[0].store_name - (路線行動超市1)
     * @property {String} response[0].orders_price - ()
     * @property {String} response[0].orders_coin - ()
     */
    get_orders_data(formInfo, mode) {
        return basePost('/api_orders/get_orders_data', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 訂單查詢-詳細
     * @param {Object} formInfo
     * @property {string} formInfo.orders_type - ( "website:線上預購,action:行動超市" )
     * @property {string} formInfo.orders_id - 訂單ID ()
     * 
     * 行動超市回傳內容
     * @return {Object} response
     * @property {String} response.id - id (2)
     * @property {String} response.orders_number - (C202203150001)
     * @property {String} response.orders_time - (2022-03-15 14:2)
     * @property {String} response.orders_status_str - (待領取)
     * @property {String} response.recipient_name - (mukitest)
     * @property {String} response.recipient_mobile - (0912345678)
     * @property {String} response.pick_up_date - (2022/05/11)
     * @property {String} response.pick_up_time_str - (11:00~12:00)
     * @property {String} response.action_store_name - (長億大樓)
     * @property {String} response.action_store_address - (台中市西屯區台灣大道2段910號)
     * @property {String} response.ori_total_format - (90)
     * @property {String} response.discount_coin_format - (10)
     * @property {String} response.discount_coupon_format - (0)
     * @property {String} response.orders_total_format - (80)
     * @property {Array} response.orders_detail - ()
     * @property {Array} response.orders_detail[0].product_name - (預設商品)
     * @property {Array} response.orders_detail[0].pic_src - (/product_picture_20220315104432792353.png)
     * @property {Array} response.orders_detail[0].sale_price - (90)
     * @property {Array} response.orders_detail[0].sale_point - (0)
     * @property {Array} response.orders_detail[0].amount - (1)
     * 
     * 線上預購回傳內容
     * @return {Object} response
     * @property {String} response.id - id (2)
     * @property {String} response.orders_number - (PL20220527123029)
     * @property {String} response.orders_time - (2022/05/27 12:30)
     * @property {String} response.orders_status_str - (待領取)
     * @property {String} response.recipient_name - (沐奇測試)
     * @property {String} response.recipient_mobile - (0912345678)
     * @property {String} response.get_start_time - (2022/03/29)
     * @property {String} response.get_end_time - (9999/12/31)
     * @property {String} response.store_name - (民權店)
     * @property {String} response.store_address - (彰化縣鹿港鎮民權路115號)
     * @property {String} response.ori_total_format - (150)
     * @property {String} response.discount_coin_format - (10)
     * @property {String} response.discount_coupon_format - (0)
     * @property {String} response.orders_total_format - (140)
     * @property {Array} response.orders_detail - ()
     * @property {Array} response.orders_detail[0].product_name - (全規則商品(勿刪))
     * @property {Array} response.orders_detail[0].pic_src - (/product_picture_20220329174108939823.jpg)
     * @property {Array} response.orders_detail[0].product_spec_name_1 - (黑1)
     * @property {Array} response.orders_detail[0].product_spec_name_2 - (白1)
     * @property {Array} response.orders_detail[0].sale_price - (150)
     * @property {Array} response.orders_detail[0].sale_point - (0)
     * @property {Array} response.orders_detail[0].amount - (1)
     */
    get_orders_detail(formInfo, mode) {
        return basePost('/api_orders/get_orders_detail', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 取消訂單
     * @param {Object} formInfo
     * @property {string} formInfo.orders_type - ( "website:線上預購,action:行動超市" )
     * @property {string} formInfo.orders_id - 訂單ID ( 20 )
     * 
     * @return {Object} response
     */
    orders_cancel(formInfo, mode) {
        return basePost('/api_orders/orders_cancel', formInfo, {mode}).then(res => {
            return res;
        })
    },

    /**
     * 重新付款
     * @param {Object} formInfo
     * @property {String} formInfo.id - 訂單ID
     * 
     * @return {Object} response
     */
    goCash(formInfo, mode) {
        return basePost('/api_orders/goCash', formInfo, {mode}).then(res => {
            return res;
        })
    },
}


// 會員
const Member = {

    /**
     * 創建BarCode
     * @param {Object} formInfo
     * @property {string} formInfo.Barcode - barcode
     * 
     * @return {Object} response
     */
    createBarcode(formInfo, mode) {
        return basePost('/api_member/createBarcode', formInfo, {mode}).then(res => {
            return res;
        })
    },

    /**
     * 登入
     * @param {Object} formInfo
     * @property {string} formInfo.account - 帳號 (mukidev)
     * @property {string} formInfo.password - 密碼 (123456)
     * 
     * @return {Object} response
     */
    login(formInfo, mode) {
        return basePost('/api_member/login', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 刪除會員
     * @return {Object} response
     */
    deleteMember(formInfo, mode) {
        return basePost('/api_member/delectMember', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 會員資訊
     * @param {Object} formInfo
     * 
     * @return {Object} response
     * @property {String} response.id - id (2)
     * @property {String} response.member_name - 會員姓名 (測試人員)
     * @property {String} response.gender - 性別 (1)
     * @property {String} response.birthday - 生日 (1983-08-16)
     * @property {String} response.phone - 電話 (047766317)
     * @property {String} response.email - 信箱 (mukicorn@gmail.com)
     * @property {String} response.city_name - 縣市 (臺中市)
     * @property {String} response.area_name - 區域 (中區)
     * @property {String} response.address - 地址 (100號)
     * @property {String} response.is_edm - is_edm (0)
     * @property {String} response.member_card - member_card (null)
     * @property {String} response.member_no - member_no (null)
     * @property {String} response.vehicle - vehicle (null)
     * @property {String} response.action_store_name - action_store_id ()
     * @property {String} response.action_store_name - action_store_name (西屯區精明路52-2號)
     * @property {String} response.action_store_addr - action_store_addr (台中市123)
     * @property {String} response.store_name - store_name (民權店)
     * @property {String} response.store_addr - store_addr (彰化縣鹿港鎮民權路115號)
     * @property {Number} response.is_blacklist - 會員被設為黑名單 (0:黑名單(否), 1:黑名單(是))
     */
    member_data(formInfo, mode) {
        return baseGet('/api_member/member_data', formInfo, {mode}).then(res => {
            return res;
        })
    },

    /**
     * 同步API會員資料
     * @param {Object} formInfo
     * @property {Number} formInfo.action_store_id - 行動超市ID (1)
     * 
     * @return {Object} response
     */
    member_data_sync(formInfo, mode) {
        return basePost('/api_member/member_data_sync', formInfo, {mode}).then(res => {
            return res;
        })
    },

    /**
     * 取得會員優惠劵
     * @param {Object} formInfo
     * @property {string} formInfo.type - 優惠劵狀況 (0:未使用(可使用),1:已使用,2:已過期)
     * 
     * @return {Object} response
     * @property {string} response.id - id (2)
     * @property {string} response.topic - topic (20200204 - 週年慶)
     * @property {string} response.full_code - full_code (498398718)
     * @property {string} response.achieve_price - achieve_price (100.0000)
     * @property {string} response.discount_price - discount_price (50.0000) // 折扣金額(折扣類型為1時值為%，折扣類型為2時為金額)
     * @property {string} response.get_date - get_date (2020-02-04)
     * @property {string} response.start_date - start_date (2020-02-01)
     * @property {string} response.end_date - end_date (2024-12-31)
     * @property {string} response.use_status - use_status (0)
     * @property {string} response.use_date - use_date (0000-00-00)
     * @property {string} response.discount_type - 優惠劵狀況 (0:未使用(可使用),1:已使用,2:已過期)
     * @property {string} response.get_source - get_source ()
     */
    get_member_coupon(formInfo, mode) {
        return basePost('/api_member/get_member_coupon', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 會員註冊
     * @param {Object} formInfo
     * @property {string} formInfo.member_card - 會員卡號 (XXXXXXXXX)
     * @property {string} formInfo.mobile - 手機號碼 (0912345678)
     * @property {string} formInfo.password - 密碼 (123546)
     * @property {string} formInfo.member_name - 會員姓名 (muki)
     * @property {string} formInfo.birthday - 會員生日 (1990-05-06)
     * @property {string} formInfo.city_name - 城市名稱 (台中市)
     * @property {string} formInfo.area_name - 區域名稱 (西屯區)
     * @property {string} formInfo.address - 地址 (台灣大道2段910號)
     * @property {string} formInfo.gender - 性別 (M:男 , F:女, N:不公開)
     * 
     * @return {Object} response
     */
    member_register(formInfo, mode) {
        return basePost('/api_member/member_register', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 忘記密碼修改
     * @param {Object} formInfo
     * @property {string} formInfo.mobile - 會員電話 (0912345678)
     * @property {string} formInfo.captcha - 驗證碼 (123456)
     * @property {string} formInfo.password - 密碼 (123456)
     * @property {string} formInfo.password_chk - 密碼確認 (123456)
     * 
     * @return {Object} response
     */
    forget_password_edit(formInfo, mode) {
        return basePost('/api_member/forget_password_edit', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 忘記密碼修改
     * @param {Object} formInfo
     * @property {string} formInfo.mobile - 手機(帳號) (0988316701)
     * @property {string} formInfo.gender - 性別 (0:女、1:男、2:不公開)
     * @property {string} formInfo.birthday - 會員生日 (1990-05-06)
     * @property {string} formInfo.email - EMAIL (mukitest@gmail.com)
     * @property {string} formInfo.is_edm - 是否電子DM (0:否，1:是)
     * @property {string} formInfo.vehicle - 載具 (/XXXXX2LS)
     * @property {string} formInfo.phone - 市話 (0423151777)
     * @property {string} formInfo.city_name - 城市名稱 ()
     * @property {string} formInfo.area_name - 區域名稱 ()
     * @property {string} formInfo.address - 地址 ()
     * @property {Number} formInfo.default_store_id - 預購商品取貨門市 ()
     * @property {Number} formInfo.default_action_store_id - 行動超市取貨點 ()
     * 
     * @return {Object} response
     */
    member_edit(formInfo, mode) {
        return basePost('/api_member/member_edit', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 密碼修改
     * @param {Object} formInfo
     * @property {string} formInfo.old_password - 舊密碼 (123456)
     * @property {string} formInfo.password - 密碼 (123456)
     * @property {string} formInfo.password_chk - 密碼確認 (123456)
     * 
     * @return {Object} response
     */
    member_password_edit(formInfo, mode) {
        return basePost('/api_member/member_password_edit', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 有無未讀優惠劵
     * @param {Object} formInfo
     * 
     * @return {Object} response 
     * @property {Number} response.unread - (0:無未讀優惠劵，1:有未讀優惠劵)
     */
    is_member_coupon_unread(formInfo, mode) {
        return basePost('/api_member/is_member_coupon_unread', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 會員優惠劵-全部已讀
     * @param {Object} formInfo
     * 
     * @return {Object} response 
     */
    member_coupon_read(formInfo, mode) {
        return basePost('/api_member/member_coupon_read', formInfo, {mode}).then(res => {
            return res;
        })
    },
}

// 遊戲
// const Playground = {
//     record(formInfo, mode) {
//         return basePost('/api_playground/play_history', formInfo, mode).then(res => {
//             return res;
//         })
//     },
//     openedGameList(formInfo, mode) {
//         return basePost('/api_playground/opened_game_list', formInfo, mode).then(res => {
//             return res;
//         })
//     },
//     farmGameList(formInfo, mode) {
//         return basePost('/api_playground/farm_game_list', formInfo, mode).then(res => {
//             return res;
//         })
//     },
//     playAction(formInfo, mode) {
//         return basePost('/api_playground/play_action', formInfo, mode).then(res => {
//             return res;
//         })
//     },
//     playDetail(formInfo, mode) {
//         return basePost('/api_playground/play_detail', formInfo, mode).then(res => {
//             return res;
//         })
//     },
// }

// // 優惠資訊
const DiscountInfo = {
    info(formInfo, mode) {
        return basePost('/api_discount_info/info', formInfo, {apiBase: apiBaseWeb}).then(res => {
            return res;
        })
    },
}

// 驗證
const Verify = {
    /**
     * 取得手機驗證碼-忘記密碼
     * @param {Object} formInfo
     * @property {string} response.mobile - 會員電話 (09122345678)
     * @property {string} response.run_sms - 是否寄送SMS (0否 1是  0用來驗證電話是否為會員電話)
     * 
     * @return {Object} response
     */
    send_forget_captcha(formInfo, mode) {
        return basePost('/api_verify/send_forget_captcha', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 驗證手機驗證碼
     * @param {Object} formInfo
     * @property {string} response.type - 種類 (forget:忘記密碼,register:註冊)
     * @property {string} response.captcha - 驗證碼 (123456)
     * 
     * @return {Object} response
     */
    sms_captcha_validate(formInfo, mode) {
        return basePost('/api_verify/sms_captcha_validate', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 取得註冊驗證碼
     * @param {Object} formInfo
     * @property {string} response.mobile - 會員電話 (09122345678)
     * @property {string} response.run_sms - 是否寄送SMS ( 0否 1是  0用來驗證電話是否為會員電話)
     * 
     * @return {Object} response
     */
    send_register_captcha(formInfo, mode) {
        return basePost('/api_verify/send_register_captcha', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 驗證驗證碼
     * @param {Object} formInfo
     * @property {string} response.captcha_name - 亂數名稱 (254841)
     * @property {string} response.captcha - 使用者輸入的驗證碼 ()
     * 
     * @return {Object} response
     */
    captcha_validate(formInfo, mode) {
        return basePost('/api_verify/captcha_validate', formInfo, {mode}).then(res => {
            return res;
        })
    },
}

// 訊息
const Notice = {
    /**
     * 取得未讀訊息數量
     * @param {Object} formInfo
     * 
     * @return {Object} response
     * @property {Number} response.num - 數量 (1)
     */
    notice_unread_num(formInfo, mode) {
        return basePost('/api_notice/notice_unread_num', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 取得訊息類別
     * @param {Object} formInfo
     * 
     * @return {Object} response
     * @property {Array} response.notice_category - ()
     * @property {Number} response.notice_category[0].id - (1)
     * @property {String} response.notice_category[0].name - (活動訊息)
     */
    notice_category(formInfo, mode) {
        return basePost('/api_notice/notice_category', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 取得訊息列表
     * @param {Object} formInfo
     * @property {Number} formInfo.page - 第幾頁(預設1) (1)
     * @property {Number} formInfo.per_page - 一頁幾筆(預設10) ()
     * @property {Number} formInfo.category - 分類ID(預設1) ()
     * 
     * @return {Object} response
     * @property {Number} response.total_page - 總頁數 (1)
     * @property {Array} response.notice_list - ()
     * @property {Number} response.notice_category[0].notice_id - (1)
     * @property {String} response.notice_category[0].member_id - (2)
     * @property {String} response.notice_category[0].notice_category - (2)
     * @property {String} response.notice_category[0].source_type - (2)
     * @property {String} response.notice_category[0].source_id - (2)
     * @property {String} response.notice_category[0].topic - (ADF)
     * @property {String} response.notice_category[0].content - (ADSF)
     * @property {String} response.notice_category[0].public_time - (2020-10-22 09:58:37)
     * @property {String} response.notice_category[0].is_read - (0)
     * @property {String} response.notice_category[0].read_time - (null)
     * @property {String} response.notice_category[0].status - (1)
     * @property {String} response.notice_category[0].modifier - (mukidev)
     * @property {String} response.notice_category[0].create_time - (2020-10-22 09:58:57)
     * @property {String} response.notice_category[0].modify_time - (2021-08-06 14:39:15)
     * @property {String} response.notice_category[0].id - (2)
     * @property {String} response.notice_category[0].date - (2020-10-22)
     */
    notice_list(formInfo, mode) {
        return basePost('/api_notice/notice_list', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 取得訊息詳細頁
     * @param {Object} formInfo
     * 
     * @return {Object} response
     * @property {String} response.notice_id - 訊息ID (1)
     * @property {String} response.topic -  (ADF)
     * @property {String} response.content -  (ADF)
     * @property {String} response.public_time -  (2020-10-22 09:58:37)
     * @property {String} response.prev_id -  ()
     * @property {String} response.next_id -  ()
     */
    notice_detailed(formInfo, mode) {
        return basePost('/api_notice/notice_detailed', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 訊息列表-標註已讀
     * @param {Object} formInfo
     * @property {Array} formInfo.notice_id - 訊息ID(陣列) ([1,2])
     * 
     * @return {Object} response
     */
    notice_read(formInfo, mode) {
        return basePost('/api_notice/notice_read', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 訊息列表-全部已讀
     * @param {Object} formInfo
     * @property {Number} formInfo.notice_category - 訊息種類 1:活動訊息,2:訂單通知,3:系統公告
     * 
     * @return {Object} response
     */
    notice_read_all(formInfo, mode) {
        return basePost('/api_notice/notice_read_all', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 訊息列表-刪除訊息
     * @param {Object} formInfo
     * @property {Array} response.notice_id - 訊息ID(陣列) ([1,2])
     * 
     * @return {Object} response
     */
    notice_delete(formInfo, mode) {
        return basePost('/api_notice/notice_delete', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 訊息列表-全部刪除
     * @param {Object} formInfo
     * 
     * @return {Object} response
     */
    notice_delete_all(formInfo, mode) {
        return basePost('/api_notice/notice_delete_all', formInfo, {mode}).then(res => {
            return res;
        })
    },
}

// 行動超市
const Action = {
    /**
     * 取得行動超市推薦產品
     * @param {Object} formInfo
     * @property {Number} formInfo.product_id - 行動超市產品ID (9)
     * 
     * @return {Object} response
     * @property {Array} response.product_list - ()
     * @property {Number} response.product_list[0].id - (9)
     * @property {Number} response.product_list[0].spec_id - (9)
     * @property {Number} response.product_list[0].is_collect - (0)
     * @property {String} response.product_list[0].countdown - (9999-12-31 00:00:00)
     * @property {String} response.product_list[0].name - (預設商品)
     * @property {String} response.product_list[0].pic_src - (/assets/uploads/800x600/product_picture_20220315104432792353.png)
     * @property {Number} response.product_list[0].stock - (9)
     * @property {Number} response.product_list[0].ori_price - (0.00)
     * @property {Number} response.product_list[0].price - (0.00)
     * @property {Number} response.product_list[0].points - (0)
     */
    recommend(formInfo, mode) {
        console.log('formInfo',formInfo);
        return basePost('/api_action/product_recommend', formInfo, {mode}).then(res => {
            return res;
        })
    },

    /**
     * 取得行動超市
     * @param {Object} formInfo
     * @property {Number} formInfo.page - 第幾頁 (1, 預設1)
     * @property {Number} formInfo.per_page - 一頁幾筆 (10, 預設10)
     * @property {String} formInfo.keyword - 關鍵字 ()
     * @property {Number} formInfo.lat - 緯度 ()
     * @property {Number} formInfo.lng - 經度 ()
     * @property {Boolean} formInfo.is_collect - 是否為最愛 (有填寫TRUE，未填寫FALSE)
     * 
     * @return {Object} response
     * @property {Number} response.total_page -  (1)
     * @property {Object} response.default -  ()
     * @property {String} response.default.id -  (1)
     * @property {Array} response.list -  ()
     * @property {Number} response.list[0].id -  (1)
     * @property {String} response.list[0].pic_src -  (http://muki.civil.center.com/assets/uploads/480x480/action_store_20220309172815878141.png)
     * @property {Number} response.list[0].is_collect -  (1)
     * @property {String} response.list[0].name -  (西屯區精明路52-2號)
     * @property {String} response.list[0].address -  (台中市123)
     * @property {String} response.list[0].lat -  (24.161926)
     * @property {String} response.list[0].lng -  (120.649498)
     * @property {String} response.list[0].distance -  (0)
     * @property {Array} response.list[0].route_list -  ()
     * @property {String} response.list[0].route_list[0].open_time -  (21:00~23:00)
     * @property {String} response.list[0].route_list[0].open_days -  (星期一、二、四、五、六、日)
     */
    get_action_store_list(formInfo, mode) {
        return basePost('/api_action/get_action_store_list', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 行動超市-加入蒐藏
     * @param {Object} formInfo
     * @property {Number} formInfo.action_store_id - 行動超市ID (1)
     * 
     * @return {Object} response
     */
    action_store_add_collect(formInfo, mode) {
        return basePost('/api_action/action_store_add_collect', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 行動超市-取消蒐藏
     * @param {Object} formInfo
     * @property {Number} formInfo.action_store_id - 行動超市ID (1)
     * 
     * @return {Object} response
     */
    action_store_remove_collect(formInfo, mode) {
        return basePost('/api_action/action_store_remove_collect', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 行動超市產品分類
     * @param {Object} formInfo
     * 
     * @return {Object} response
     * @property {Number} response[key].id - (35)
     * @property {String} response[key].name - (人氣熱銷)
     * @property {Array} response[key].sub_category - ()
     * @property {Number} response[key].sub_category[0].id - (38)
     * @property {String} response[key].sub_category[0].name - (分類1-1)
     */
    action_store_product_category(formInfo, mode) {
        return basePost('/api_action/action_store_product_category', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 行動超市-產品列表
     * @param {Object} formInfo
     * 
     * @return {Object} response
     * @property {Number} response.total_page - (1)
     * @property {Array} response.product_list - ()
     * @property {Number} response.product_list[0].id - (9)
     * @property {Number} response.product_list[0].spec_id - (9)
     * @property {Number} response.product_list[0].is_collect - (0)
     * @property {String} response.product_list[0].countdown - (9999-12-31 00:00:00)
     * @property {String} response.product_list[0].name - (預設商品)
     * @property {String} response.product_list[0].pic_src - (/assets/uploads/800x600/product_picture_20220315104432792353.png)
     * @property {Number} response.product_list[0].stock - (9)
     * @property {Number} response.product_list[0].ori_price - (0.00)
     * @property {Number} response.product_list[0].price - (0.00)
     * @property {Number} response.product_list[0].points - (0)
     */
    action_product_list(formInfo, mode) {
        return basePost('/api_action/action_product_list', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 行動超市-產品詳細資料
     * @param {Object} formInfo
     * @property {Number} response.product_id - 行動超市-產品ID (1)
     * 
     * @return {Object} response
     * @property {Number} response.id - (1)
     * @property {Number} response.spec_id - (0)
     * @property {Number} response.is_collect - (0)
     * @property {String} response.name - (預設商品)
     * @property {Number} response.stock - (1)
     * @property {Number} response.ori_price - (1)
     * @property {Number} response.price - (0)
     * @property {Number} response.points - (0)
     * @property {String} response.pics - (/assets/uploads/800x600/product_picture_20220315104432792353.png)
     * @property {Stirng} response.pickup_date - ()
     * @property {Array} response.description_list - ()
     * @property {Number} response.description_list[0].id - (9)
     * @property {Number} response.description_list[0].product_id - (9)
     * @property {String} response.description_list[0].topic - (商品特色)
     * @property {String} response.description_list[0].content - ()
     */
    action_product_detailed(formInfo, mode) {
        return basePost('/api_action/action_product_detailed', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 行動超市-產品加入蒐藏
     * @param {Object} formInfo
     * @property {Number} response.product_id - 行動超市-產品ID (9)
     * 
     * @return {Object} response
     */
    action_add_collect(formInfo, mode) {
        return basePost('/api_action/action_add_collect', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 行動超市-產品取消蒐藏
     * @param {Object} formInfo
     * @property {Number} response.product_id - 行動超市-產品ID (9)
     * 
     * @return {Object} response
    */
    action_remove_collect(formInfo, mode) {
        return basePost('/api_action/action_remove_collect', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 取貨日期
     * @param {Object} formInfo
     * @property {Number} formInfo.action_store_id - (9)
     * 
     * @return {Object} response
     * @property {String} response[0] - (2022-06-02)
    */
    action_store_pickup_date(formInfo, mode) {
        return basePost('/api_action/action_store_pickup_date', formInfo, mode).then(res => {
            return res;
        })
    },
    /**
     * 取貨時間
     * @param {Object} formInfo
     * @property {Number} formInfo.action_store_id - 行動超市-產品ID (1)
     * @property {Number} formInfo.pickup_date - 取貨日期 (2022-06-02)
     * 
     * @return {Object} response
     * @property {String} response[0] - (21:00-23:00)
    */
    action_store_pickup_time(formInfo, mode) {
        return basePost('/api_action/action_store_pickup_time', formInfo, {mode}).then(res => {
            return res;
        })
    },
}

// 遊戲
const Game = {
    /**
     * 取得可參加的遊戲列表
     * @param {Object} formInfo
     * 
     * @return {Object} response
     * @property {Number} response.id -  (2)
     * @property {string} response.name -  (開心種子)
     * @property {string} response.content -  (151235<br />\r\n&nbsp;)
     * @property {string} response.start_datetime -  (2022-02-07 00:00:00)
     * @property {string} response.end_datetime -  (2022-02-07 00:00:00)
     * @property {Number} response.sort_id -  (1)
     * @property {string} response.status -  (1)
     * @property {string} response.modifier -  (mukidev)
     * @property {string} response.create_time -  (2022-02-07 00:00:00)
     * @property {string} response.modify_time -  (2022-02-07 00:00:00)
     * @property {string} response.member_game_id - (null, 有值就代表已經有選擇遊戲，要值接進澆水頁)
     * @property {string} response.end_date -  (null)
     * @property {string} response.finish -  (null)
     * @property {string} response.die_time - 乾枯時間 (2022-02-07 00:00:00)
     * @property {string} response.is_die - 是否已乾枯
     */
    game_list(formInfo, mode) {
        return basePost('/api_game/game_list', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 取得遊戲種子列表
     * @param {Object} formInfo
     * @property {Number} response.id -  (2)
     * 
     * @return {Object} response
     * @property {Number} response.id -  (2)
     * @property {string} response.game_id -  (2)
     * @property {string} response.harvest_pic -  (20220406105304410912.jpg)
     * @property {string} response.name -  (開心種子)
     * @property {string} response.instruction -  (種子說明)
     * @property {string} response.num -  (19)
     * @property {string} response.harvest_time -  (19)
     * @property {string} response.watering_time -  (19)
     * @property {string} response.stage_num -  (19)
     * @property {string} response.die_time -  (3600)
     * @property {string} response.sort_id -  (1)
     * @property {string} response.status -  (1)
     * @property {string} response.modifier -  (1)
     * @property {string} response.create_time -  (2022-03-17 14:42:25)
     * @property {string} response.modify_time -  (2022-03-17 14:42:25)
     * @property {string} response.harvest_pic_src -  (410912.jpg)
     */
    game_seed_list(formInfo, mode) {
        return basePost('/api_game/game_seed_list', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 參加遊戲
     * @param {Object} formInfo
     * 
     * @return {Object} response
     * @property {Number} response.game_id - 遊戲ID (2)
     * @property {Number} response.game_seed_id - 遊戲種子ID (2)
     */
    join_game(formInfo, mode) {
        return basePost('/api_game/join_game', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 取得參加遊戲的相關參數
     * @param {Object} formInfo
     * @property {Number} formInfo.game_id - ()
     * 
     * @return {Object} response
     * @property {Number} response.current_progress - ()
     * @property {Number} response.countdown - ()
     * @property {Number} response.total_stage - ()
     * @property {Number} response.current_stage - ()
     * @property {Array} response.stages - ()
     * @property {String} response.stages[0].id - ()
     * @property {String} response.stages[0].title - ()
     * @property {String} response.stages[0].pic - ()
     * @property {String} response.stages[0].stage - ()
     */
    member_game_detail_data(formInfo, mode) {
        return basePost('/api_game/member_game_detail_data', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 澆水
     * @param {Object} formInfo
     * 
     * @return {Object} response
     */
    watering(formInfo, mode) {
        return basePost('/api_game/watering', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 澆水
     * @param {Object} 領取獎勵
     * 
     * @return {Object} response
     */
    receive_award(formInfo, mode) {
        return basePost('/api_game/receive_award', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 取得遊戲紀錄
     * @param {Object} formInfo
     * @property {Number} formInfo.game_id - 遊戲ID (2)
     * 
     * @return {Object} response
     * @property {String} response.award_type - 獎勵類型 (point:點數,coupon:優惠劵 )
     * @property {Number} response.point - 點數 (50)
     * @property {Number} response.achieve_price - 優惠劵門檻金額 ()
     * @property {Number} response.discount_price - 折扣金額 (折扣類型為1時值為%，折扣類型為2時為金額)
     * @property {Number} response.discount_type - 折扣類型 (1:百分比折扣、2:現金折抵)
     */
    get_game_award(formInfo, mode) {
        return basePost('/api_game/get_game_award', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 取得遊戲紀錄
     * @param {Object} formInfo
     * @property {Number} formInfo.page - 頁碼 (1, 預設1)
     * @property {Number} formInfo.per_page - 一頁幾筆 (10, 預設10)
     * 
     * @return {Object} response
     * @property {Number} response.total_page - ()
     * @property {Array} response.list - ()
     * @property {String} response.list[0].member_id - (2)
     * @property {String} response.list[0].game_id - (2)
     * @property {String} response.list[0].log_data - (恭喜獲得50點喜美點數)
     * @property {String} response.list[0].create_time - (2022-04-07 09:52:18)
     */
    get_member_game_log(formInfo, mode) {
        return basePost('/api_game/get_member_game_log', formInfo, {mode}).then(res => {
            return res;
        })
    },
}

// 其他
const Other = {
    /**
     * 橫幅
     * @param {Object} formInfo
     * 
     * @return {Object} response
    */
    get_banner(formInfo, mode) {
        return baseGet('/api_other/get_banner', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 燈箱
     * @param {Object} formInfo
     * 
     * @return {Object} response
     * @property {String} response.url_link -  ()
     * @property {String} response.pic_src -  ()
    */
    get_light_commercial(formInfo, mode) {
        return baseGet('/api_other/get_light_commercial', formInfo, {mode}).then(res => {
            return res;
        })
    },
}

// 其他
const Common = {
    get_now_time(formInfo, mode) {
        return baseGet('/api_common/get_now_time', formInfo, {mode}).then(res => {
            return res;
        })
    }
}


// 陽昇API
const YoungSun = {
    /**
     * 會員點數記錄
     * @param {Object} formInfo
     * @property {String} formInfo.member_no - 會員number (XXXXXXXXX)
     * @property {String} formInfo.search_y - 查詢年份 (2022)
     * @property {String} formInfo.search_m - 查詢月份 (05)
     * 
     * @return {Object} response
     * @property {String} response.total_point - 目前總點數 ()
     * @property {String} response.log_list - 紀錄列表 ()
    */
    member_point_record(formInfo, mode) {
        return basePost('/member_point_record', formInfo, {apiBase: apiBaseYoungSun}).then(res => {
            return res;
        })
    },
    /**
     * 點數比例
     * @param {Object} formInfo
     * 
     * @return {Object} response
     * @property {String} response.point_proportion - 點數比例 (回傳10，10點換1台幣、回傳5，5點換一台幣)
    */
    point_proportion(formInfo, mode) {
        return basePost('/point_proportion', formInfo, {apiBase: apiBaseYoungSun}).then(res => {
            return res;
        })
    },
    /**
     * 會員手機號碼查詢
     * 
     * - member_card 有填寫時，才需回傳res_data(舊會員帶入原本會員資訊使用)
     * - member_card 無填寫，只需回傳res_code(用來判定手機是否在POS系統有註冊過，若有需回傳"會員卡號")
     * 
     * @param {Object} formInfo
     * @property {String} formInfo.member_card  - 會員卡號 (XXXXXXXXX)
     * @property {String} formInfo.member_mobile - 會員手機 (2022)
     * 
     * @return {Object} response
     * @property {String} response.member_card - 會員卡號 ()
     * @property {String} response.member_name - 會員姓名 ()
     * @property {String} response.birthday - 會員生日 ()
     * @property {String} response.sex_flag - 性別  M:男 , F:女, N:不公開 ()
    */
    get_member_data(formInfo, mode) {
        return basePost('/get_member_data', formInfo, {apiBase: apiBaseYoungSun}).then(res => {
            return res;
        })
    },
    /**
     * 會員卡號查詢 
     * @param {Object} formInfo
     * @property {String} formInfo.member_no  - 會員號碼 (XXXXXXXXX)
     * 
     * @return {Object} response
     * @property {String} response.data.res_data.member_card - 會員卡號 (XXXXXXXXX)
     * 
    */
    get_member_card_number(formInfo, mode) {
        return basePost('/get_cust_card', formInfo, {apiBase: apiBaseYoungSun}).then(res => {
            return res;
        })
    },
    /**
     * 會員登入狀態查詢
     * @param {Object} formInfo
     * @property {String} formInfo.member_card  - 會員卡號 (XXXXXXXXX)
     * @property {String} formInfo.member_mobile - 會員手機 (2022)
     * 
     * @return {Object} response
     * @property {String} response.data.res_data.member_card - 會員卡號 (XXXXXXXXX)
     * 
    */
    get_member_login_state(formInfo, mode) {
        return basePost('/get_member_data', formInfo, {apiBase: apiBaseYoungSun}).then(res => {
            return res;
        })
    },
}

// 條款
const Terms = {
    /**
     * 隱私權政策
     * @param {Object} formInfo
     * 
     * @return {Object} response
    */
    privacy_policy(formInfo, mode) {
        return baseGet('/api_terms/privacy_policy', formInfo, {mode}).then(res => {
            return res;
        })
    },
    /**
     * 會員條款
     * @param {Object} formInfo
     * 
     * @return {Object} response
    */
    member_terms(formInfo, mode) {
        return baseGet('/api_terms/member_terms', formInfo, {mode}).then(res => {
            return res;
        })
    },
}


/**
 * 登入
 * @param {Object} formInfo
 * @property {Number} formInfo.account - 帳號 (mukidev)
 * @property {Number} formInfo.password - 密碼 (123456)
 * 
 * @return {Object} response
 */
function Login(formInfo, isSilent = false){
    return Member.login(formInfo).then(res=>{
        ajax200Res(
        res,
        ({res, data, msg}) => {

            // 推播相關功能必要
            _get_mobile_info()

            store.commit('auth/setData', {
                isLogined: true,
                token: data
            })

            // 取得最新資料
            store.dispatch('updateMemberRelatedData')

            // success
            if ( msg && isSilent ) {
                f7.view.main.router.navigate('/', {
                    ignoreCache: true,
                    reloadCurrent: true,
                    clearPreviousHistory: true,
                    // transition: 'f7-fade',
                    animate:false,
                })
            } else {
                alertCustom("訊息提示", msg, () => {
                    f7.view.main.router.navigate('/', {
                        ignoreCache: true,
                        reloadCurrent: true,
                        clearPreviousHistory: true,
                        // transition: 'f7-fade'
                        animate:false,
                    })
                });
            }
        },
        ({res, data, msg, defaultAlert}) =>{
            // fail
            defaultAlert()
        });
    })
}

/**
 * 會員刪除
 * @param {Object} formInfo
 * @property {Number} formInfo.account - 帳號 (mukidev)
 * @property {Number} formInfo.password - 密碼 (123456)
 * 
 * @return {Object} response
 */
function DeleteMember(formData, isSilent = false){
    confirmCustom('提示', '會員刪除後將無法使用同組帳號進行登入，確定刪除帳號?',
    function(){
        console.log('fail');
    },
    function(){
        return Member.deleteMember(formData).then(res=>{
            ajax200Res(
            res,
            ({res, data, msg}) => {
                // success
                if ( msg && isSilent ) {
                    Logout()
                } else {
                    alertCustom("訊息提示", msg, () => {
                        Logout()
                    });
                }
            },
            ({res, data, msg, defaultAlert}) =>{
                // fail
                defaultAlert()
            });
        })
    });
}


function Logout(formData, isSilent = false){

    // 清除推播相關綁定
    _get_mobile_info()

    store.commit('auth/setData', {
        isLogined: false,
    });

    store.commit('setData', {
        adModalHideThisLogin: false
    }) 

    f7.view.main.router.navigate('/', {
        ignoreCache: true,
        clearPreviousHistory: true,
        animate:false,
        // transition: 'f7-fade'
    });
}

/**
 * 註冊 - 驗證碼
 * @param {Object} formInfo
 * @property {Number} formInfo.mobile - 帳號 (0908331254)
 * 
 * @return {Object} response
 */
function registerAndForgetPwdCaptcha(formInfo, isSilent = false){
    return new Promise((resolve, reject)=>{
        const toSend1 = {
            mobile: formInfo.mobile,
            run_sms: mukiConst.runSms.NO
        };

        const toSend2 = {
            mobile: formInfo.mobile,
            run_sms: mukiConst.runSms.YES
        };

        // 驗證碼。 註冊與忘記密碼相同API
        Verify.send_register_captcha(toSend1).then(res=>{

            ajax200Res(
            res,
            ({res, data, msg}) => {
                // success
                Verify.send_register_captcha(toSend2).then(res=>{
                    resolve(res)
                })
            },
            ({res, data, msg, defaultAlert}) =>{

                resolve(res)
            });

        }).catch(()=>{
            
            reject(res)
        })
    })
}

/**
 * 修改購物車
 * 
 * 因為修改購物車數量時，如果是"行動超市"，會須另外判斷是否要多執行"選擇行動超市api"
 * 因此封裝邏輯於此
 * 
 * @param {Object} formInfo
 * @property {Number} formInfo.product_id - 產品ID ()
 * @property {Number} formInfo.product_spec_id - 產品SPECID ()
 * @property {String} formInfo.cart_type - (action:行動超市,website:預購)
 * @property {Number} formInfo.amount - 數量 (1)
 * @property {Number} formInfo.type - (rewrite: 複寫、addition: 增加)
 * @property {Number} [formInfo.product_spec_price_id]- 產品SPEC_PRICE_ID(只有預購需填寫) (9)
 * 
 * @param {Number} [currentSelectedActionStoreId] - 當前選擇的行動超市ID
 * @param {Number} [thisProductsActionStoreId] - 這個商品被加入購物車當下，使用者所瀏覽的行動超市ID
 * 
 * @return {Object} response
 */
function CartItemProcess(formInfo, currentSelectedActionStoreId, thisProductsActionStoreId){
    return new Promise(async (resolve, reject)=>{

        // 是行動超市嗎
        var isActionStore = formInfo.cart_type == mukiConst.cartType.ACTION_STORE;
        // 行動超市已經換不同家了
        var isActionStoreChanged = currentSelectedActionStoreId != thisProductsActionStoreId;
        // 輸入的action id是完整的
        var isActionIdComplete = thisProductsActionStoreId !== undefined && thisProductsActionStoreId !== '';

        const res = await Cart.cart_items_process(formInfo)

        const {onSuccess, onFail } = ajax200Res(res)

        onSuccess(async({res, data, msg, defaultAlert})=>{
            // success
            // 更新購物車數量
            const res2 = await store.dispatch('cart/updateCurrentCartNum')

            const {onSuccess, onFail } = ajax200Res(res2)

            onSuccess(async ({res, data, msg, defaultAlert})=>{
                /**
                 * 原容 : 需要確定「行動超市購物車」數量 > 0 才能呼叫選擇行動超市api
                 * 故將呼叫流程設定在更新完購物車數量且確認「行動超市購物車」有東西
                 * 
                 * -) 當使用者選擇行動超市的商品，且行動超市已經換不同間，就呼叫切換api
                 */

                // 行動超市購物車有東西
                var actionStoreCartHasItem = data && data.mobile_store && data.mobile_store > 0

                if ( isActionStore && isActionIdComplete && actionStoreCartHasItem ) {
                    // 購物車-選擇行動超市
                    const res3 = await Cart.select_action_store({
                        action_store_id: thisProductsActionStoreId
                    })

                    const {onSuccess, onFail } = ajax200Res(res3)

                    onSuccess(({res, data, msg, defaultAlert})=>{
                        // 即時更新最新資料
                        store.dispatch('cart/updateCurrentCartSelectedStore');
                    })

                    onFail(({res, data, msg, defaultAlert})=>{

                    })
                }
            })

            onFail(({res, data, msg, defaultAlert})=>{

            })
            

            // 購物車數量修改已成功執行
            resolve(res)
        });

        onFail(({res, data, msg, defaultAlert})=>{
            resolve(res)
        });
        
        
    })
}


export {
    setApiAuthconfig,
    
    baseGet,
    basePost,
    basePut,
    basePatch,
    baseDel,

    // ==== API ====

    // 購物車
    Cart,
    CartItemProcess,


    // 條款
    Terms,
    // 陽昇API
    YoungSun,
    // 首頁
    Index,
    // 信件
    Mail,
    // DM
    DM,
    // 產品
    Product,
    // 生活提案
    Proposal,
    // 優惠券
    Coupon,
    // 門市
    Store,
    // 最新消息
    News,
    // 訂單
    Order,
    // 會員
    Member,
    // 遊戲
    // Playground,
    // 優惠資訊
    DiscountInfo,
    // 客服中心
    Contact,
    // 驗證
    Verify,
    // 訊息
    Notice,
    // 行動超市
    Action,
    // 遊戲
    Game,
    // 其他
    Other,
    Common,


    // ==== Wrapped API ====

    // 註冊 - 驗證碼
    registerAndForgetPwdCaptcha,
    // 登入/登出
    Login,
    Logout,
    // 刪除會員
    DeleteMember,
};
