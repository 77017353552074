import { Member } from "@/js/services/baseService";
import { computed, reactive } from "vue";
import creteStoreUtil from '@/store/store-util';
var defaultState = {
  // 行動超市列表
  list: []
};

var _creteStoreUtil = creteStoreUtil({
  defaultState: defaultState
}),
    init = _creteStoreUtil.init,
    getData = _creteStoreUtil.getData,
    setData = _creteStoreUtil.setData,
    resetData = _creteStoreUtil.resetData,
    initState = _creteStoreUtil.initState;

export default {
  namespaced: true,
  state: function state() {
    return {};
  },
  getters: {
    getData: getData
  },
  actions: {
    init: init
  },
  mutations: {
    initState: initState,
    setData: setData,
    resetData: resetData,
    updateStore: function updateStore(state, payload) {
      if (!payload) return;
      state.list.forEach(function (store) {
        if (store.id === payload.storeId) {
          if (payload.isCollect !== undefined && payload.isCollect !== null) {
            store.is_collect = payload.isCollect;
          }
        }
      });
    }
  }
};