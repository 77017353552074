import { baseGet, basePost } from "@/js/services/baseService";
import { computed, reactive } from "vue";
import storage, { PLAYGROUND_STORAGE_NAME } from '@/js/storage';
import creteStoreUtil from '@/store/store-util';

const defaultState = {
    farmGame: [],
}

const { init, getData, setData, resetData, initState } = creteStoreUtil({
    defaultState
})

export default {
    namespaced: true,
    state: () => ({
        farmGame: [],
    }),
    getters: {
        getData,
        data(state, getters){
            var result = reactive({})

            for (let key in state){
                result[key] = computed(()=> state[key])
            }

            return result
        },
    },
    actions: {
        init
    },
    mutations: {
        initState,
        setData,
        resetData,
        update(state, data){
            for ( let key in state){
                if ( data[key] !== undefined ) {
                    state[key] = data[key]
                }
            }
        },
        clear(state, data){
            for ( let key in state){
                state[key] = ''
            }
        },

        updateGameList(state, data){
            if ( state[data.name] ) {
                if ( state[data.name][data.idx] ) {
                    state[data.name][data.idx][data.key] = data.value
                }   
            }
        }
    },
}

