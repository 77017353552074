import { ref, reactive, watch } from 'vue';
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, Fragment as _Fragment } from "vue";
export default {
  expose: [],
  props: {
    absFill: Boolean,
    src: String
  },
  setup: function setup(__props) {
    var props = __props;
    var refImg = ref(null);
    var cData = reactive({
      error: props.src ? false : true
    });
    watch(function () {
      return props.src;
    }, function () {
      cData.error = false;
    });
    return function (_ctx, _cache) {
      var _component_BaseImageFallback = _resolveComponent("BaseImageFallback");

      return _openBlock(), _createBlock(_Fragment, null, [!_unref(cData).error ? (_openBlock(), _createBlock("img", {
        key: 0,
        ref: refImg,
        src: __props.src,
        alt: "",
        onerror: function onerror() {
          _unref(cData).error = true;
        }
      }, null, 8, ["src", "onerror"])) : _createCommentVNode("", true), _unref(cData).error ? (_openBlock(), _createBlock(_component_BaseImageFallback, {
        key: 1,
        absFill: __props.absFill
      }, null, 8, ["absFill"])) : _createCommentVNode("", true)], 64);
    };
  }
};