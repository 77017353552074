import localForage from "localforage";

const storage = localForage.createInstance({
    driver      : localForage.LOCALSTORAGE, // Force WebSQL; same as using setDriver()
    name        : 'mukistore',
    version     : 1.0,
    storeName   : 'mukistore', // Should be alphanumeric, with underscores.
    description : 'app 設定儲存空間'
});

const TOP_STORAGE_NAME = 'top';
const AUTH_STORAGE_NAME = 'auth';
const PLAYGROUND_STORAGE_NAME = 'playgournd';

export default storage;
export {
    TOP_STORAGE_NAME,
    AUTH_STORAGE_NAME,
    PLAYGROUND_STORAGE_NAME,
}