import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { YoungSun } from "@/js/services/baseService";
export default {
  name: 'BarcodePopup',
  setup: function setup() {
    var memberCard = ref('');
    var store = useStore(); // onMounted(() => {
    //     getMemberCardNumber();
    // });

    function getMemberCardNumber() {
      setTimeout(function () {
        YoungSun.get_member_card_number({
          member_no: store.state.auth.member_no
        }).then(function (res) {
          var data = res.data;
          memberCard.value = data.res_data.member_card;
        }).catch(function (error) {
          console.error('呼叫API錯誤:', error);
        });
      }, 0);
    }

    return {
      memberCard: memberCard,
      getMemberCardNumber: getMemberCardNumber
    };
  }
};