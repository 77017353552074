const CONST_BASE = {
    // ==== 模組 ====
    // 語系
    locale:{
        TW: 'tw',
        EN: 'en'
    },
    // 驗證
    csrToken:{
        INVALID: -99,
    },
    token:{
        INVALID: -98,
    },
    // 性別
    gender: {
        FEMALE: 0,
        MALE: 1,
        EMPTY: 2
    },
    // 同意
    accept:{
        NO: 0,
        YES: 1
    },

    // ==== 專案 ====
    // 庫存
    stock:{
        // 缺貨
        OUT_OF_STOCK: 1
    },
    // 會員身份
    memberType:{
        // 一般
        GENERAL: 0,
        // 業務
        SALES: 1,
    },
    // 發票
    invoiceType: {
        // 2聯
        DUPLICATE_UNIFORM_INVOICE: 1,
        // 3聯
        TRIPLICATE_UNIFORM_INVOICE: 2,
        // 捐贈
        DONATE: 3,
    },
    // 付款方式
    paymentMethod: {
        // 刷卡
        CARD: 'credit',
        // 台灣pay
        TAIWAN_PAY: 'taiwanPay'
    },
    // 遊戲
    game:{
        status:{
            IS_DIE: 1,
        }
    },
    // 優惠券
    coupon:{
        category:{
            PERCENT: 1,
            PRICE: 2,
        },
        type: {
            // 未使用
            UNUSED: 0,
            // 已使用
            USED: 1,
            // 已過期
            EXPIRED: 2,
        },
        applyTo:{
            // 門市/ APP使用
            STORE_AND_APP: 0,
            // 指定商品適用
            SPECIFIC_ITEM: 1
        },
        // 是否適用於購物車內商品
        canUseCheckout:{
            NO: 0,
            YES: 1,
        }
    },
    // 訂購流程種類
    // orderCategory:{
    //     //線上預購
    //     ONLINE_PREORDER: 'website',
    //     // 行動超市
    //     ACTION_STORE: 'action',
    // },
    // orderType: {
    //     //線上預購
    //     ONLINE_PREORDER: 'website',
    //     // 行動超市
    //     ACTION_STORE: 'action',
    // },
    cartType: {
        //線上預購
        ONLINE_PREORDER: 'website',
        // 行動超市
        ACTION_STORE: 'action',
    },
    // 訂單狀態
    orderStatus:{
        // 待領取
        WAITING_COLLECT: 0,
        // 已領取
        COLLECTED: 1,
        // 提貨期限已過未取貨
        NOT_COLLECT: 2,
        // 取消訂單
        CANCELED: 9,
    },
    // 商品庫存不足時，處理方式
    whenOutOfStock:{
        // 取消整筆訂單
        CANCEL_ORDER: 0,
        // 接受訂單部分缺貨，只取有貨的品項
        ACCEPT_PARTIAL_ORDER: 1,
    },
    // 行動超市
    actionStore: {
        list: {
            ALL: 0,
            FAVORITE: 1,
        }
    },
    // 產品排序
    productSort: {
        // 系統推薦
        DEFAULT: 0,
        // 最多人購買
        POPULAR: 1,
        // 價格低到高
        PRICE_ASC: 2,
        // 價格高到低
        PRICE_DESC: 3,
    },
    // 手機載具條碼綁定方式
    member: {
        memberCardBindingType:{
            // 卡號+行動電話
        }
    },
    // 黑名單
    blackList:{
        NO: 0,
        YES: 1,
        MESSAGE: '多次未取貨<br>系統將暫時取消您訂購資格'
    },

    runSms:{
        NO: 0,
        YES: 1,
    }
}

const CONST_PROD = {
    WEB_API_BASE_URL: 'https://www.civil-mart.com.tw/webApi/',
}

const CONST_DEV = {
    WEB_API_BASE_URL: 'http://muki_civil_web.com/webApi/',
}


const CONST = process.env.NODE_ENV == 'development' ? $.extend(true, {}, CONST_BASE, CONST_DEV) :  $.extend(true, {}, CONST_BASE, CONST_PROD);


export default CONST