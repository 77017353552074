// Import Vue
import { createApp } from 'vue';

// Import Framework7
import Framework7 , {Dom7} from 'framework7/lite-bundle';

// Import Framework7-Vue Plugin
import Framework7Vue, { registerComponents, f7, f7ready } from 'framework7-vue/bundle';

import store from '@/store/store.js';

// Import Framework7 Styles
import 'framework7/framework7-bundle.css';

// Import Icons and App Custom Stylesnpm
import '@/css/icons.css';
import '@/css/app.scss';

// Import App Component
import App from "@/App.vue";

// 表單驗證
import { autoSetLocal } from "@/js/validations.js";

// i18n
import { i18n } from "@/js/i18n.js";

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

/**
 * pollyfill allSettled
 */
Promise.allSettled = Promise.allSettled || ((promises) => Promise.all(
    promises.map(p => p
        .then(value => ({
            status: "fulfilled",
            value
        }))
        .catch(reason => ({
            status: "rejected",
            reason
        }))
    )
));


let app = createApp(App);

// store
app.use(store);

// global vars
app.config.globalProperties.$window = window;

store.restored.then(async ()=>{

    await store.dispatch('init');

    app.use(i18n);

    // Register Framework7 Vue components
    registerComponents(app);

    // 驗證套件語系
    autoSetLocal();
    
    // Mount the app
    app.mount('#app');

})

// F7 可使用時
f7ready(() => {
    // Framework7's initialized instance

    // F7 相關全域變數
    app.config.globalProperties.$f7 = f7;
    // 給舊模組mobile.js使用
    window.f7 = f7;
    window.$$ = Dom7;
})


