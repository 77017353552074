import { computed } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue";
var _hoisted_1 = {
  key: 0
};
export default {
  expose: [],
  props: {
    num: {
      type: [Number, String],
      default: 0
    },
    // 以dot顯示
    showAsDot: [Boolean, Number],
    size: String,
    bg: String,
    // 定位
    absPosition: Boolean,
    // 粗體
    bold: Boolean
  },
  setup: function setup(__props) {
    var props = __props;
    var thisNum = computed(function () {
      return parseFloat(props.num);
    });
    return function (_ctx, _cache) {
      var _component_f7_badge = _resolveComponent("f7-badge");

      return _unref(thisNum) > 0 || __props.showAsDot ? (_openBlock(), _createBlock(_component_f7_badge, {
        key: 0,
        class: ["muki-num-badge", {
          'is-over-99': _unref(thisNum) > 99,
          'sty-bold': __props.bold ? true : null,
          'muki-num-badge_abs-position': __props.absPosition || null
        }],
        style: {
          '--muki-num-badge-bg': __props.bg || null,
          '--muki-num-badge-size': __props.size || null
        }
      }, {
        default: _withCtx(function () {
          return [!__props.showAsDot ? (_openBlock(), _createBlock("span", {
            key: 0,
            class: {
              'fz-trans-10px': parseInt(_unref(thisNum)) > 10
            }
          }, [_createTextVNode(_toDisplayString(_unref(thisNum) > 99 ? 99 : _unref(thisNum)) + " ", 1), _unref(thisNum) > 99 ? (_openBlock(), _createBlock("span", _hoisted_1, "+")) : _createCommentVNode("", true)], 2)) : _createCommentVNode("", true)];
        }),
        _: 1
      }, 8, ["class", "style"])) : _createCommentVNode("", true);
    };
  }
};