import { Member } from "@/js/services/baseService";
import { computed, reactive } from "vue";
import creteStoreUtil from '@/store/store-util';

const defaultState = {
    // 行動超市列表
    list: []
}

const { init, getData, setData, resetData, initState } = creteStoreUtil({
    defaultState
})

export default {
    namespaced: true,

    state: () => ({
    }),
    getters: {
        getData,
    },
    actions: {
        init,
    },
    mutations: {
        initState,
        setData,
        resetData,
        updateProduct(state, payload){

            if ( !payload ) return;

            state.list.forEach(product => {
                if ( product.id === payload.productId ) {
                    if ( payload.isCollect !== undefined && payload.isCollect !== null ) {
                        product.is_collect = payload.isCollect
                    }
                }
            })

        }
    },
}

