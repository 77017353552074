import { computed, reactive } from "vue";

function creteStoreUtil(setting){
    var setting = setting || {
        defaultState: {}
    }

    var defaultState = setting.defaultState;

    function init({commit}){
        return new Promise((resolve, reject)=>{
            commit('initState');
            resolve()
        })
    }

    function getData(state, getters){
        var result = reactive({})
    
        for (let key in state){
            result[key] = computed(()=> state[key])
        }
    
        return result
    }
    
    function setData(state, data){
        for ( let key in state){
            if ( data[key] !== undefined ) {
                state[key] = data[key]
            }
        }
    }
    
    function resetData(state){
        for(let key in defaultState){
            state[key] = defaultState[key]
        }
    }
    
    function initState(state){
        for(let key in defaultState){
            // 使用 hasOwnProperty 來避開 vuex-persit套件已經幫我們從storage寫回來vuex store的值
            if ( !state.hasOwnProperty(key) ){
                state[key] = defaultState[key]
            }
        }
    }

    return {
        init,
        getData,
        setData,
        resetData,
        initState
    }
}

export default creteStoreUtil;
export {
    creteStoreUtil
}