import { computed, ref } from '@vue/runtime-core';
import { addToCollection, removeFromCollection } from "@/js/services/baseService";
export default {
  props: {
    isFav: {
      type: [Boolean, String, Number],
      default: false
    },
    // active時outline
    activeOutline: Boolean,
    // not active時 灰色
    notActiveGray: Boolean
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var thisIsFav = computed(function () {
      if ('string' === typeof props.isFav) {
        if (props.isFav.trim() === '0') {
          return false;
        }
      }

      return props.isFav;
    });

    var toggleFav = function toggleFav() {
      if (thisIsFav.value) {
        emit('toggleNotFav');
      } else {
        emit('toggleFav');
      }
    };

    return {
      toggleFav: toggleFav,
      thisIsFav: thisIsFav
    };
  }
};