import { reactive } from "vue";
import store from "@/store/store.js";
export default {
  components: {},
  props: {},
  setup: function setup(props, context) {
    var pageData = reactive({});
    return {
      store: store
    };
  }
};