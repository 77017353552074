import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_NumBadge = _resolveComponent("NumBadge");

  return _openBlock(), _createBlock("i", {
    class: ["", $props.icon]
  }, [_createVNode(_component_NumBadge, {
    absPosition: "",
    bold: "",
    num: $setup.number
  }, null, 8, ["num"])], 2);
}