import { computed, reactive } from "vue";
import creteStoreUtil from '@/store/store-util';

const defaultState = {
    farmGame: [],
}

const { init, getData, setData, resetData, initState } = creteStoreUtil({
    defaultState
})

export default {
    namespaced: true,
    state: () => ({
        // 預購商品取貨 - 選擇後且尚未送出的資料
        tempStoreId: '',
        tempStoreName: '',
        tempStoreAddress: '',
        // 行動超市 - 選擇後且尚未送出的資料
        tempActionStoreId: '',
        tempActionStoreName: '',
        tempActionStoreAddress: '',
    }),
    getters: {
        getData,
    },
    actions: {
        init
    },
    mutations: {
        initState,
        setData,
        resetData,
    },
}

