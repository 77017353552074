import { defineRule, configure, validate } from "vee-validate";
import { confirmed, email, numeric, required, min, max } from '@vee-validate/rules';
import { i18n } from "@/js/i18n.js";
import { localize, setLocale } from '@vee-validate/i18n';
import { f7 } from "framework7-vue";
import storage, { TOP_STORAGE_NAME } from '@/js/storage';
import store from "@/store/store.js";

/**
 * isEmpty 參照 vee-validate
 * @param {*} value 
 * @returns {Boolean}
 */
function isEmpty(value) {
    if (value === null || value === undefined || value === '') {
        return true;
    }
    if (Array.isArray(value) && value.length === 0) {
        return true;
    }
    return false;
}

configure({
    // Generates an English message locale generator
    generateMessage: localize('tw', {
        messages: {
            required: "必填",
            email: '請輸入正確的信箱格式',
            confirmed: '輸入值不相符',
            phoneCheck: '欄位不符合格式，僅接受數字及「-」',
            verifyMobile: '需為數字及開頭為09、含10個字元',
            verifyIdentify: '驗證碼錯誤',
            confirmedPwd: '密碼不一致，請重新輸入',
            verifyEmail: '請輸入正確的信箱格式',
            numeric: '請輸入數值',
            'project@Address': '請輸入完整地址',
            eInvoiceVehicle: '請輸入正確手機載具條碼',
            'onlyChineseAndEnglish' : '僅可輸入中英文字',
            max: "不可超過10個字",
        },
    }),
    generateMessage: localize('en', {
        messages: {
            required: 'required',
            email: 'Please enter the correct mailbox format',
            confirmed: 'Input value does not match',
            phoneCheck: 'The field only numbers and "-" are accepted',
            verifyMobile: 'Must be a number and start with 09, containing 10 characters',
            verifyIdentify: 'Verification code error',
            confirmedPwd: 'Passwords are inconsistent, please re-enter',
            verifyEmail: 'Please enter the correct mailbox format',
            numeric: 'Please enter a number',
            'project@Address': 'Please enter full address',
            eInvoiceVehicle: 'Please enter the correct mobile phone vehicle barcode',
            'onlyChineseAndEnglish' : 'Only Chinese and English characters can be entered',
            max: "Cannot exceed 10 characters",
        },
    }),
});

// 自動設定語系
function autoSetLocal(){
    storage.getItem( TOP_STORAGE_NAME ).then((value)=>{
        var locale = (value && value.locale) || store.state.locale
        setLocale(locale);
    });
}

// 官方堤共的 rule
defineRule('email', email);
defineRule('confirmed', confirmed);
defineRule('numeric', numeric);
defineRule("required", required);
defineRule("min", min);
defineRule("max", max);



// 驗證全中英名稱
defineRule("onlyChineseAndEnglish", (value) => {
    const pattern = /^[\u4E00-\u9FA5A-Za-z]+$/; // 中文和英文的正則表達式
    return pattern.test(value);
});

// 專案的地址客制要求
defineRule("project@Address", async(value) => {
    // 地址欄位最少10個字元
    var value = value || '';
    var num = 0;

    String(value).split('').forEach(c=>{
        var count = new Blob([c]).size
        
        if ( count == 3 ) {
            // 視為中文 當作2個字元
            num+=2
        } else {
            // 其餘當作1字元
            num+=1
        }
    })

    return num >= 10
});


// 驗證密碼
defineRule("verifyPassword", (value) => {
    if (store.state.verifyPatterns.common.test(value)) return true;

    return store.state.commonErrorMsg;
});

// 驗證電話格式
defineRule("phoneCheck", (value) => {
    var re = new RegExp('^[\\d\-]{1,}$');
    if (!value) return true;
    if ( re.test(value) ) return true;
    return false;
});

// 密碼格式
defineRule("customPassword", (value) => {

    if (!value) return true;
    
    var containsEng = new RegExp('[a-zA-Z]').test(value);
    var containsNumber = new RegExp('[0-9]').test(value);
    var containsUnderline = new RegExp('[_]').test(value);
    var lengthCheck = new RegExp('^[\\w]{6,12}$').test(value);
    var result = containsEng && containsNumber && !containsUnderline && lengthCheck;

    if ( result ) return true;

    return '密碼限為6~12位英數字混合';
});

// 驗證手機格式
defineRule("verifyMobile", (value) => {
    if (/^[09]{2}[0-9]{8}$/.test(value)) return true;
    return false;
});


// 手機載具
defineRule("eInvoiceVehicle", (value) => {
    if (isEmpty(value)) return true;

    if (/^\/[0-9A-Z.+-]{7}$/.test(value)) return true;

    return false;
});

// 閱讀及同意
defineRule('acceptPolicyRequired', value => {
    if (value) {
        return true;
    }
    return '如已閱讀及同意 請勾選(必填)'; 
});

export {
    autoSetLocal
}