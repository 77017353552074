import { ref, watch } from 'vue';
import { useStore } from 'vuex';
import { onMounted } from '@vue/runtime-core';
export default {
  props: {
    watchStore: Boolean
  },
  setup: function setup(props, _ref) {
    var emit = _ref.emit;
    var store = useStore();
    var refLoadingWrapText = ref(null);
    var refRoot = ref(null); // 開啟

    function loadingOpen(text) {
      if (text) {
        $(refLoadingWrapText.value).text(text);
      } else {
        $(refLoadingWrapText.value).text('');
      }

      $(refRoot.value).show();
    } // 關閉


    function loadingClose() {
      $(refRoot.value).hide();
    }

    if (props.watchStore) {
      watch(function () {
        return store.state.isGlobalLoadingShow;
      }, function () {
        console.log('change!!!!');

        if (store.state.isGlobalLoadingShow) {
          loadingOpen();
        } else {
          loadingClose();
        }
      });
    }

    onMounted(function () {
      emit('init', {
        loadingOpen: loadingOpen,
        loadingClose: loadingClose
      });
    });
    return {
      refLoadingWrapText: refLoadingWrapText,
      refRoot: refRoot
    };
  }
};