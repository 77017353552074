export const MukiUtil = {
    /**
     * 是否是jQuery 物件
     * @param {*} target 
     * @returns Boolean
     */
    isjQueryObject: function (target) {
        return target instanceof jQuery
    },
    /**
     * 是否是 ISO8601 日期字串格式
     * @param {*} data 
     * @returns Boolean
     */
    isIsoDate: function(data){
        if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(data)) return false;
        var d = new Date(str); 
        return d.toISOString()===str;
    },
    /**
     * 是否是 JSON 
     * @param {*} data 
     * @returns Boolean
     */
    isJSON: function(data){
        
    },
    /**
     * 轉成數值
     * @param {*} data 
     * @returns Number
     */
    toNumber: function (data) {
        if ('number' === typeof data) {
            return data
        }
        if ('string' === typeof data) {
            var n = parseInt(data.trim().replace(/,/g, ""));
            return n;
        }
    },
    /**
     * 產生uuid
     * @returns String
     */
    uuid: function () {
        var d = Date.now();
        if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
            d += performance.now(); //use high-precision timer if available
        }
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    },
    /**
     * 不是undefined
     * @param {*} data 
     * @returns Boolean
     */
    notUndefined(data){
        return data !== undefined
    },
    /**
     * 不是null
     * @param {*} data 
     * @returns Boolean
     */
    notNull(data){
        return data !== null
    },
    /**
     * 不是空值
     * @param {*} data 
     * @returns Boolean
     */
    notEmpty(data){
        return data !== ''
    },
    /**
     * 產生min到max之間的亂數
     * @param {*} min 
     * @param {*} max 
     * @returns Number
     */
    getRandom(min,max){
        return Math.floor(Math.random()*(max-min+1))+min;
    }
}

/**
 * 簡易數值解析
 * 
 * 簡易，故只考慮$、分位、小數點。其餘須從來源端修正
 * 
 * @param {Number|String} numberOrString - ("15,000")
 * @return {Number} result
 */

export function parseSimpleNumber(numberOrString){

    var isString = 'string' === typeof numberOrString;
    var parsedValue = parseFloat(numberOrString);
    var isNumber = !isString && !isNaN(parsedValue);

    if ( isNumber ) return numberOrString

    if ( isString ) {
        var cleaned = numberOrString;

        // 分位
        cleaned = numberOrString.replace(/,/g,'')
        // $
        cleaned = cleaned.replace(/\$/g,'')

        cleaned = parseFloat(cleaned)

        return cleaned
    }

    return 0
}

/**
 * api回傳值parse
 * @param {*} res 
 * @param {*} success 
 * @param {*} fail 
 * @returns {Object} return
 * @property {Function} return.onSuccess - ( ({res, data, msg, defaultAlert }) => void )
 * @property {Function} return.onFail - ( ({res, data, msg, defaultAlert }) => void )
 */
export function ajax200Res(res, success, fail){

    let successCallback = '';
    let failCallback = '';

    // flag
    let isSuccess = false;
    // 給callback的資料
    let dataToSend = '';
    let msg = '';

    let defaultAlert = () => { 
        return new Promise((resolve, reject)=>{

            if ( !msg ) {
                resolve()
                return
            } 

            _alert_custom("訊息提示", msg, function(){
                resolve()
            })
        })
    };

    function onSuccess(callBack){

        if ( !isSuccess ) return

        if ( 'function' === typeof callBack ) {
            successCallback = callBack
            handleCallBack()
        }
    }

    function onFail(callBack){

        if ( isSuccess ) return

        if ( 'function' === typeof callBack ) {
            failCallback = callBack;
            handleCallBack()
        }
    }

    function handleCallBack(isArgumentCallBack){
        // 透過 isArgumentCallBack 來避免重複執行callback

        const toSend = {res, data:dataToSend, msg, defaultAlert}

        if ( isSuccess ) {
            if ( 'function' === typeof successCallback && !isArgumentCallBack ) {
                successCallback(toSend)
            } 
            if ( 'function' === typeof success && isArgumentCallBack ) {
                success(toSend)
            }
        } else {
            if ( 'function' === typeof failCallback && !isArgumentCallBack ) {
                failCallback(toSend)
            }
            if ( 'function' === typeof fail && isArgumentCallBack ) {
                fail(toSend)
            }
        }
    }

    // 解析Res
    function parseRes(){

        if ( res ) {

            const { data, status } = res;

            if ( data ) {
    
                const resData = data['res_data'];
                msg = data["res_content"];
                dataToSend = resData ? resData : data;
    
                if ( status == 200 && data["res_code"] == 1 ) {

                    isSuccess = true;
    
                } else {
                    // fail
                    // 不是200 + 沐奇自訂res_code == 1，就視為fail
    
                };
    
            } else {
                // fail
                // 沒有 data 視為fail
            }
        } else {
            // fail
            // 沒有 res 視為fail
        }
    }

    // 解析
    parseRes();

    // 等解析完成
    handleCallBack(true);

    return {
        onSuccess,
        onFail
    }
}

/**
 * 建立一個Pending 狀態的 Promise
 * @returns {Object}
 * @property {Object} promise - promise
 * @property {Function} resolve - resolve
 * @property {Function} reject - reject
 */
export function pendingPromise(){
            
    var outerResolve;
    var outerReject;

    var promise = new Promise((resolve, reject)=>{
        outerResolve = resolve;
        outerReject = reject;
    })

    return {
        promise,
        resolve: outerResolve,
        reject: outerReject 
    }
}

/**
 * Canvas 是否是空的
 * @param {*} canvas 
 * @returns {Boolean}
 */
export function isCanvasBlank(canvas) {
    var blank = document.createElement('canvas');
    blank.width = canvas.width;
    blank.height = canvas.height;

    blank.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
    return canvas.toDataURL() == blank.toDataURL();
}
