// import store from '../store/store.js'
import Home from "@/pages/Home";
import Login from '@/pages/Login.vue';
import NotFoundPage from "@/pages/404.vue";
import store from '@/store/store'
import { YoungSun } from "@/js/services/baseService";

var routes = [
    {
        path: "/",
        /**
         * 由此入口統一管理導向
         * 
         * - 不使用async，因async每次都會重新載入頁面，無法keep alive
         * - 需要async的頁面，可以在此resolve到該path，再由該path去做async
         */
        redirect: function ({to, resolve, reject}) {

            const isLogined = store.state.auth.isLogined;

            console.log('to', to, isLogined)

            if ( to.query.go_page && to.query.go_page_mode === 'route_load' ) {

                resolve('/route_load' + to.url)

            } else if ( isLogined ) { 

                resolve('/home')

            } else {

                resolve('/login')
            }

        },
    },
    {
        path: "/home",
        // component: Home,
        asyncComponent: () => import("@/pages/Home"),
        beforeEnter: function({ resolve, reject }){
            console.log('beforeenter')
            resolve()
        },
        /**
         * 使用keepAlive後，navigate加上ignoreCache是無作用的
         */
        // keepAlive: true,
    },
    {
        path: "/login",
        component: Login,
    },
    // 由route直接加載的頁面
    {
        path: "/route_load",
        async: async function ({ app, to, resolve }) {

            /**
             * 判斷是否有指定直接由route載載入頁面 ( go_page 且載入方式為 route_load )
             * 1) 採用此種方式載入的頁面須注意返回箭頭是無作用的。可以直接將返回指定為首頁，或是直接不要顯示返回鍵
             * 2) 且不會執行註冊在 page:afterin == 首頁的程式，因為route是直接載入本頁面，並未經過首頁
             */
            if ( to.query.go_page && to.query.go_page_mode === 'route_load' ) {
                
                /**
                 * -) 在app中，需清掉query指令，避免換頁後重複執行
                 * -) 在網頁中，不清掉query，讓使用者可停留在註冊頁
                 */
                if ( m_deviceType() !== 0 ) {
                    history.pushState(null, '', location.origin );
                }

                if ( to.query.go_page === 'product_detailed') {

                    const page = await import("@/pages/ProductDetailed");

                    if (to.query.isShareMode == 0) {
                        resolve({
                            component: page.default
                        }, {
                            props:{
                                // 是否是分享模式
                                backPage: 'home',
                                isShareMode: false,
                                productId: to.query.product_id,
                                cartType: to.query.cart_type,
                            }
                        })
                    }else{
                        resolve({
                            component: page.default
                        }, {
                            props:{
                                // 是否是分享模式
                                isShareMode: true,
                                productId: to.query.product_id,
                                cartType: to.query.cart_type,
                            }
                        })
                    }

                }

            }
        }
    },
    {
        path: "/dm_list",
        keepAlive: true,
        asyncComponent: () => import("@/pages/DMList"),
    },
    {
        path: "/forgot_pwd",
        asyncComponent: () => import("@/pages/ForgotPwd"),
    },
    {
        path: "/change_pwd",
        asyncComponent: () => import("@/pages/ChangePwd"),
    },
    {
        path: "/register_step1",
        asyncComponent: () => import("@/pages/RegisterStep1"),
    },
    {
        path: "/register_step2",
        asyncComponent: () => import("@/pages/RegisterStep2"),
    },

    // 門市據點
    {
        path: "/store_list",
        keepAlive: true,
        asyncComponent: () => import("@/pages/StoreList"),
    },
    // 會員中心
    {
        path: "/member_center",
        asyncComponent: () => import("@/pages/MemberCenter"),
    },
    // 我的優惠券
    {
        path: "/coupon_list",
        asyncComponent: () => import("@/pages/CouponList"),
    },
    // 我的優惠券
    {
        path: "/data_binding",
        asyncComponent: () => import("@/pages/DataBinding"),
    },
    // 個人資料設定
    {
        path: "/member_profile",
        asyncComponent: () => import("@/pages/MemberProfile"),
    },
    // 預購商品的取貨門市
    {
        path: "/preorder_store_list",
        asyncComponent: () => import("@/pages/PreorderStoreList"),
    },
    // 預購商品的取貨門市
    {
        path: "/preorder_store_confirm",
        asyncComponent: () => import("@/pages/PreorderStoreConfirm"),
    },
    // 預購商品的取貨門市
    {
        path: "/mobile_store_pickup_list",
        asyncComponent: () => import("@/pages/MobileStorePickupList"),
    },
    // 會員修改密碼
    {
        path: "/member_change_pwd",
        asyncComponent: () => import("@/pages/MemberChangePwd"),
    },
    // 隱私權政策
    {
        path: "/privacy_policy",
        keepAlive: true,
        asyncComponent: () => import("@/pages/PrivacyPolicy"),
        keepAlive: true,
    },
    // 會員條款
    {
        path: "/member_terms",
        asyncComponent: () => import("@/pages/MemberTerms"),
        keepAlive: true,
    },
    // 客服中心
    {
        path: "/contact_us",
        asyncComponent: () => import("@/pages/ContactUs"),
    },
    // 收件夾
    {
        path: "/mail_list",
        asyncComponent: () => import("@/pages/MailList"),
    },
    // 收件夾
    {
        path: "/mail_list",
        asyncComponent: () => import("@/pages/MailList"),
    },
    {
        path: "/mail_detailed/:mailId",
        asyncComponent: () => import("@/pages/MailDetailed"),
    },
    // 線上預購
    {
        path: "/preorder_product_list",
        asyncComponent: () => import("@/pages/PreorderProductList"),
    },
    // 產品
    {
        path: "/product_detailed/:productId/:cartType",
        asyncComponent: () => import("@/pages/ProductDetailed"),
    },
    // 生活提案
    {
        path: "/proposal_list",
        asyncComponent: () => import("@/pages/ProposalList"),
    },
    {
        path: "/proposal_detailed/:proposalId",
        asyncComponent: () => import("@/pages/ProposalDetailed"),
    },
    // 購物車
    {
        path: "/cart_step1/:cartType",
        asyncComponent: () => import("@/pages/CartStep1"),
    },
    // 選取優惠券
    {
        path: "/select_coupon",
        asyncComponent: () => import("@/pages/SelectCoupon"),
    },
    // 購物車
    {
        path: "/cart_step2/:cartType",
        asyncComponent: () => import("@/pages/CartStep2"),
    },
    // 購物車
    {
        path: "/cart_step3/:cartType",
        asyncComponent: () => import("@/pages/CartStep3"),
    },
    // 選擇門市確認
    {
        path: "/store_select_confirm/:pageTitle",
        asyncComponent: () => import("@/pages/StoreSelectConfirm"),
    },
    // 最新消息
    {
        path: "/news_list",
        asyncComponent: () => import("@/pages/NewsList"),
    },
    {
        path: "/news_detailed/:newsId",
        asyncComponent: () => import("@/pages/NewsDetailed"),
    },
    // 消費紀錄
    {
        path: "/shopping_records",
        asyncComponent: () => import("@/pages/ShoppingRecords"),
    },
    // 優惠資訊
    {
        path: "/discount_info",
        asyncComponent: () => import("@/pages/DiscountInfo"),
    },
    // 訂單查詢
    {
        path: "/order_records",
        asyncComponent: () => import("@/pages/OrderRecords"),
    },
    {
        path: "/order_detailed/",
        asyncComponent: () => import("@/pages/OrderDetailed"),
    },
    // 行動超市
    {
        path: "/mobile_store_list",
        asyncComponent: () => import("@/pages/MobileStoreList"),
    },
    // 行動超市-地圖顯示頁
    {
        path: "/action_store_location_on_map",
        asyncComponent: () => import("@/pages/ActionStoreLocationOnMap"),
    },
    // 行動超市-商品列表頁
    {
        path: "/mobile_store_product_list",
        asyncComponent: () => import("@/pages/MobileStoreProductList"),
    },

    // 遊樂場
    {
        path: "/play_ground",
        asyncComponent: () => import("@/pages/Playground"),
    },
    // 遊樂場說明
    {
        path: "/play_ground_explain",
        asyncComponent: () => import("@/pages/PlaygroundExplain"),
    },
    // 參加紀錄
    {
        path: "/attend_list",
        asyncComponent: () => import("@/pages/AttendList"),
    },
    // 灌溉
    {
        path: "/plant_page/:playId",
        asyncComponent: () => import("@/pages/PlantPage"),
    },

    // 我的收藏
    {
        path: "/my_fav_list",
        asyncComponent: () => import("@/pages/MyFavList"),
    },

    // 共用的店家地圖顯示頁
    {
        path: "/common_store_location_on_map",
        asyncComponent: () => import("@/pages/CommonStoreLocationOnMap"),
    },


    // ==== popup ====
    // 共用的店家地圖顯示頁
    {
        path: "/select_plant_popup/",
        popup:{
            asyncComponent: () => import("@/popup/SelectPlantPopup"),
        },
    },
    // ==== popup ==== /


    {
        path: "(.*)",
        component: NotFoundPage,
    },
];

export default routes;
