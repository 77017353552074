import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode } from "vue";
var _hoisted_1 = {
  class: "muki-barcode"
};
var _hoisted_2 = {
  key: 1,
  class: "center-info"
};
var _hoisted_3 = {
  class: "barcode-content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createBlock("div", _hoisted_1, [$setup.imgSrc ? (_openBlock(), _createBlock("img", {
    key: 0,
    src: $setup.imgSrc,
    alt: ""
  }, null, 8, ["src"])) : (_openBlock(), _createBlock("div", _hoisted_2, " 瀏覽器版本過低，請更新chrome瀏覽器 ")), _createVNode("div", _hoisted_3, _toDisplayString($setup.upperCaseCode), 1)]);
}