import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "toggle-not-active icon-heart-line"
};
var _hoisted_2 = {
  key: 0,
  class: "toggle-active icon-heart-full"
};
var _hoisted_3 = {
  key: 0,
  class: "toggle-active icon-heart-line"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createBlock("div", {
    class: "fav-toggle",
    onClick: _cache[1] || (_cache[1] = function () {
      return $setup.toggleFav && $setup.toggleFav.apply($setup, arguments);
    }),
    style: {
      '--not-active-color': $props.notActiveGray ? '#898989' : null
    }
  }, [!$setup.thisIsFav ? (_openBlock(), _createBlock("i", _hoisted_1)) : _createCommentVNode("", true), !$props.activeOutline ? (_openBlock(), _createBlock(_Fragment, {
    key: 1
  }, [$setup.thisIsFav ? (_openBlock(), _createBlock("i", _hoisted_2)) : _createCommentVNode("", true)], 64)) : _createCommentVNode("", true), $props.activeOutline ? (_openBlock(), _createBlock(_Fragment, {
    key: 2
  }, [$setup.thisIsFav ? (_openBlock(), _createBlock("i", _hoisted_3)) : _createCommentVNode("", true)], 64)) : _createCommentVNode("", true)], 4);
}