import { baseGet, basePost, Cart } from "@/js/services/baseService";
import { computed, reactive } from "vue";
import storage from '@/js/storage';
import creteStoreUtil from '@/store/store-util';
import { ajax200Res } from '@/js/utils';

const defaultState = {
    // 購物車產品數量 - 線上預購
    onlinePreorderNum: 0,
    // 購物車產品數量 - 行動超市
    actionStoreNum: 0,

    
    // 選擇的優惠券
    selectedCoupon:{
        achieve_price: "",
        achieve_price_format: "",
        discount_price: "",
        discount_price_format: "",
        discount_type: "",
        end_date: "",
        full_code: "",
        get_date: "",
        get_source: "",
        id: "",
        start_date: "",
        topic: "",
        use_date: "",
        use_status: "",
    },
    // 選擇的取貨門市
    selectedStore:{
        id: '',
        name: '',
        address: '',
    },
    // 選擇的行動超市
    selectedActionStore:{
        id: '',
        name: '',
        address: '',
    }
}

const { init, getData, setData, resetData, initState } = creteStoreUtil({
    defaultState
})

export default {
    namespaced: true,
    state: () => ({
    }),
    getters: {
        getData,
        // 詳細
        getCartNum(state, getters){
            return reactive({
                onlinePreOrder: computed(() => state.onlinePreorderNum),
                actionStore: computed(() => state.actionStoreNum),
            })
        },
        getSelectedCoupon(state, getters){
            return state.selectedCoupon
        },
        // 取得當前線上預購取貨門市
        getCurrentWebsiteStore(state, getters, rootState){
            // 是否有當前購物車選擇的門市
            var isHasSelectedStore = computed(()=> state.selectedStore.id && state.selectedStore.name)
            
            return reactive({
                id: computed(()=> {
                    // 購物車選擇的門市優先
                    if ( isHasSelectedStore.value ) {
                        // 購物車選擇的
                        return state.selectedStore.id 
                    } else {
                        // 會員資料
                        return rootState.auth.store_id
                    }
                }) ,
                name: computed(()=> {
                    // 購物車選擇的門市優先
                    if ( isHasSelectedStore.value ) {
                        // 購物車選擇的
                        return state.selectedStore.name;
                    } else {
                        // 會員資料
                        return rootState.auth.store_name
                    }
                }),
                address: computed(()=>{
                    // 購物車選擇的門市優先
                    if ( isHasSelectedStore.value ) {
                        // 購物車選擇的
                        return state.selectedStore.address;
                    } else {
                        // 會員資料
                        return rootState.auth.store_addr
                    }
                }),
            })
        },
        // 取得當前行動超市
        getCurrentActionStore(state, getters, rootState){
            // 是否有當前購物車選擇的超市
            var isHasSelectedStore = computed(()=> state.selectedActionStore.id )

            return reactive({
                id: computed(()=> {
                    // 購物車選擇的門市優先
                    if ( isHasSelectedStore.value ) {
                        // 購物車選擇的
                        return state.selectedActionStore.id
                    } else {
                        // 會員資料
                        return rootState.auth.action_store_id
                    }
                }) ,
                name: computed(()=> {
                    // 購物車選擇的門市優先
                    if ( isHasSelectedStore.value ) {
                        // 購物車選擇的
                        return state.selectedActionStore.name
                    } else {
                        // 會員資料
                        return rootState.auth.action_store_name
                    }
                }),
                address: computed(()=>{
                    // 購物車選擇的門市優先
                    if ( isHasSelectedStore.value ) {
                        // 購物車選擇的
                        return state.selectedActionStore.address
                    } else {
                        // 會員資料
                        return rootState.auth.action_store_addr
                    }
                }),
            })
        },
        // 取得瀏覽頁面時優先使用的行動超市
        getViewUseActionStore(state, getters, rootState){
            // 是否有紀錄的會員瀏覽的行動超市
            // var isHasViewedStore = computed(()=> rootState.auth.viewed_action_store_id );
            var currentActionStore = getters['getCurrentActionStore'];

            return {
                id: currentActionStore.id,
                name: currentActionStore.name,
                address: currentActionStore.address
            }
        }
    },
    actions: {
        init,
        // 更新 - 購物車產品數量
        async updateCurrentCartNum({commit}){
            const res = await Cart.current_cart_num()

            const {onSuccess, onFail } = ajax200Res(res)

            onSuccess(({res, data, msg, defaultAlert})=>{
                if ( data ) {
                    commit('setData', {
                        onlinePreorderNum: data.online_preorder,
                        actionStoreNum: data.mobile_store,
                    })
                }
            })
            
            onFail(({res, data, msg, defaultAlert})=>{
                defaultAlert();
            })
            
            return res
        },
        // 更新 - 購物車-取得當前選擇的行動超市資料
        async updateCurrentCartSelectedStore({commit}){
            const res = await Cart.get_cart_action_store_id()

            const {onSuccess, onFail } = ajax200Res(res)

            onSuccess(({res, data, msg, defaultAlert})=>{
                if ( data ) {
                    commit('setData', {
                        selectedActionStore:{
                            id: data.id,
                            name: data.action_store_name,
                            address: data.addr,
                        }
                    })
                }
            })

            onFail(({res, data, msg, defaultAlert})=>{
                defaultAlert();
            })
            
            return res
        },
    },
    mutations: {
        initState,
        setData,
        resetData,
        // 清空選擇的優惠券
        clearSelectedCoupon(state){
            state['selectedCoupon'] = defaultState.selectedCoupon
        }
    },
}

